const subtitles = {
    ua: {
        intro: [
            {
                "start": 100,
                "end": 1000,
                "part": "Вітаю вас!",
            },
            {
                "start": 1100,
                "end": 3400,
                "part": "Я - Луї Пастер. ",
            },
            {
                "start": 3500,
                "end": 7950,
                "part": "Мої заслуги перед людством детально описані у Вікіпедії. ",
            },
            {
                "start": 8050,
                "end": 9900,
                "part": "А як щодо ваших? ",
            },
            {
                "start": 10000,
                "end": 14350,
                "part": "Давайте дізнаємось, чи зможете вы залишитися у пам'яті нащадків. ",
            },
            {
                "start": 14450,
                "end": 17450,
                "part": "А ще краще - у рейтингу Форбс. ",
            },
            {
                "start": 17550,
                "end": 19400,
                "part": "Ця гра допоможе визначити, ",
            },
            {
                "start": 19500,
                "end": 22400,
                "part": "яку компанію ви зможете побудувати, ",
            },
            {
                "start": 22500,
                "end": 26000,
                "part": "користуючись наявними знаннями та досвідом. ",
            },
            {
                "start": 26100,
                "end": 30100,
                "part": "Отже... Для гри бажано використовувати комп'ютер. ",
            },
            {
                "start": 30200,
                "end": 33500,
                "part": "Сконцентруйтеся на питаннях та обирайте відповіді, ",
            },
            {
                "start": 33600,
                "end": 36000,
                "part": "що характеризують саме вас. ",
            },
            {
                "start": 36100,
                "end": 40200,
                "part": "Враховуйте, що змінити відповіді згодом буде неможливо. ",
            },
            {
                "start": 40300,
                "end": 46100,
                "part": "Крім того, не завадить мати під рукою папір, авторучку та калькулятор. ",
            },
            {
                "start": 46200,
                "end": 47000,
                "part": "Почнемо гру!",
            },
        ],
        login: [
            {
                "start": 100,
                "end": 4500,
                "part": "Я вже мав нагоду представитися раніше, але дозвольте повторитись: ",
            },
            {
                "start": 4600,
                "end": 6400,
                "part": "я - Луї Пастер. ",
            },
            {
                "start": 6500,
                "end": 8000,
                "part": "Приємно познайомитись.",
            },
        ],
        code: [
            {
                "start": 100,
                "end": 700,
                "part": "Почнемо! ",
            },
            {
                "start": 800,
                "end": 2650,
                "part": "Введіть код. ",
            },
            {
                "start": 2750,
                "end": 4000,
                "part": "Сміліше!",
            },
        ],
        role: [
            {
                "start": 100,
                "end": 2500,
                "part": "Уявіть вашу майбутню компанію. ",
            },
            {
                "start": 2600,
                "end": 5100,
                "part": "Ту про яку напишуть у Форбс. ",
            },
            {
                "start": 5200,
                "end": 7100,
                "part": "Час визначитися з тим, ",
            },
            {
                "start": 7200,
                "end": 10000,
                "part": "яку роль ви візьмете у цьому проєкті мрії? ",
            },
            {
                "start": 10100,
                "end": 14700,
                "part": "\n Ви станете співзасновником розділивши відповідальність із партнером? ",
            },
            {
                "start": 14800,
                "end": 21000,
                "part": "\n Можливо ви станете лідером який надихає команду своїм прикладом та плекає співпрацю? ",
            },
            {
                "start": 21100,
                "end": 29000,
                "part": "\n Або ж, Засновником - візіонером. Сильним, єнергійним та визначним лідером який веде за собою інших.",
            },
        ],
        spheres: [
            {
                "start": 100,
                "end": 2900,
                "part": "Мене завжди цікавили форми самозародження, ",
            },
            {
                "start": 3000,
                "end": 5000,
                "part": "імунологія та вакцинація. ",
            },
            {
                "start": 5100,
                "end": 8600,
                "part": "До речі, один із методів названий на мою честь. ",
            },
            {
                "start": 8700,
                "end": 12000,
                "part": "А в якій сфері ви бажаєте стати найкращим?",
            },
        ],
        spheresOther: [
            {
                "start": 100,
                "end": 1700,
                "part": "Ви обрали свій варіант? ",
            },
            {
                "start": 1800,
                "end": 4000,
                "part": " Ви мене заінтригували...",
            }
        ],
        grant: [
            {
                "start": 100,
                "end": 2450,
                "part": "А ось і перше випробування ",
            },
            {
                "start": 2550,
                "end": 4800,
                "part": "наскільки серйозні ваші наміри ",
            },
            {
                "start": 4900,
                "end": 7900,
                "part": "– Ви маєте отримати грант від держави. ",
            },
            {
                "start": 8000,
                "end": 10000,
                "part": "Але є умови. ",
            },
            {
                "start": 10100,
                "end": 12750,
                "part": "\n1. Вам необхідно надати персональні дані. ",
            },
            {
                "start": 12850,
                "end": 13900,
                "part": "Чим більше даних, ",
            },
            {
                "start": 14000,
                "end": 16800,
                "part": "тим вища ймовірність отримання гранту. ",
            },
            {
                "start": 16900,
                "end": 18700,
                "part": "\n2. Гроші ви отримуєте одразу, ",
            },
            {
                "start": 18800,
                "end": 21250,
                "part": "а інформацію про себе передасте ",
            },
            {
                "start": 21350,
                "end": 23900,
                "part": "після успішної побудови компанії.",
            },
            {
                "start": 24000,
                "end": 26700,
                "part": "\n3. Дані слід надіслати в Телеграм бот, ",
            },
            {
                "start": 26800,
                "end": 30900,
                "part": "посилання на який ви отримуєте на останньому екрані.",
            },
            {
                "start": 31000,
                "end": 34300,
                "part": "\n4. А якщо ви \"забудете\" відзвітувати державі, ",
            },
            {
                "start": 34400,
                "end": 36900,
                "part": "держава цього не забуде. ",
            },
            {
                "start": 37000,
                "end": 39200,
                "part": "Чи варто наражати свою компанію ",
            },
            {
                "start": 39300,
                "end": 42000,
                "part": "на репутаційні ризики та судові розгляди?",
            },
        ],
        motivations: [
            {
                "start": 100,
                "end": 2800,
                "part": "Отриманної від держави допомоги та накопичень ",
            },
            {
                "start": 2900,
                "end": 7800,
                "part": "вистачило для запуску бета версії продукту, і ви переконалися, ",
            },
            {
                "start": 7900,
                "end": 10100,
                "part": "що \"справа пахне мільйонами, ",
            },
            {
                "start": 10200,
                "end": 12000,
                "part": "проєкт виходить перспективний та амбітний\". ",
            },
            {
                "start": 13000,
                "end": 16000,
                "part": "Але будь-які ресурси закінчуються, ",
            },
            {
                "start": 16500,
                "end": 19000,
                "part": "а потім зникає і мотивація команди. ",
            },
            {
                "start": 20000,
                "end": 21000,
                "part": "Вам потрібно приділити увагу людям! ",
            },
            {
                "start": 23000,
                "end": 25000,
                "part": "Як ви їх мотивуватимете?",
            },
        ],
        investors: [
            {
                "start": 100,
                "end": 1800,
                "part": "Хм! Вражає! ",
            },
            {
                "start": 1900,
                "end": 4400,
                "part": "Ви вмієте приймати правильні рішення. ",
            },
            {
                "start": 5400,
                "end": 6400,
                "part": "Вами зацікавилися інвестори, ",
            },
            {
                "start": 7800,
                "end": 10700,
                "part": "які готові надати кошти, для запуску товару на ринку. ",
            },
            {
                "start": 11800,
                "end": 13000,
                "part": "Кому з інвесторів ви віддасте перевагу?",
            },
        ],
        investorsOther: [
            {
                "start": 100,
                "end": 1400,
                "part": "Ви обрали власний варіант! ",
            },
            {
                "start": 1500,
                "end": 3000,
                "part": "Заінтригували...",
            }
        ],
        noInvestorSteveJobs: [
            {
                "start": 100,
                "end": 2200,
                "part": "Так, Стів - дивовижна людина! ",
            },
            {
                "start": 2300,
                "end": 5000,
                "part": "Хоча за життя нам так і не пощастило по працювати разом. ",
            },
        ],
        challenge: [
            {
                "start": 100,
                "end": 1900,
                "part": "Згадую свої успіхи... ",
            },
            {
                "start": 2500,
                "end": 5800,
                "part": "Багато вчених працювали у тому ж напрямку, що і я, ",
            },
            {
                "start": 6600,
                "end": 7900,
                "part": "але хто зараз вже згадає їхні імена...",
            },
            {
                "start": 11400,
                "end": 12000,
                "part": "До чого це я?!... ",
            },
            {
                "start": 12600,
                "end": 14900,
                "part": "Ви, здається, успішно розвиваєтесь! ",
            },
            {
                "start": 15200,
                "end": 18000,
                "part": "Ви запустили продукт й почали активний продаж. ",
            },
            {
                "start": 19300,
                "end": 20600,
                "part": "Ваша доля ринку зростає. ",
            },

            {
                "start": 21500,
                "end": 24000,
                "part": "Але... ваша компанія, на жаль, ",
            },
            {
                "start": 24600,
                "end": 27000,
                "part": "не єдина, хто заробляє в обраній ніші. ",
            },
            {
                "start": 27500,
                "end": 28000,
                "part": "Є інші. ",
            },
            {
                "start": 29000,
                "end": 35400,
                "part": "Кожен ваш крок разом з кроками інших гравців може створювати можливості для всіх, ",
            },
            {
                "start": 35500,
                "end": 41000,
                "part": "а може давати вам тимчасову перевагу або навпаки зробити вразливим. ",
            },
            {
                "start": 41800,
                "end": 45000,
                "part": "Вам потрібно продовжувати заробляти, щоб розвивати власний продукт. ",
            },
            {
                "start": 46400,
                "end": 49000,
                "part": "Уважно вивчіть поточні правила на ринку: ",
            },
            {
                "start": 49300,
                "end": 53000,
                "part": "4 комбінації можливих дій вас та інших гравців. ",
            },
            {
                "start": 54000,
                "end": 56000,
                "part": "Комбінації можуть примножити ваш заробіток... ",
            },
            {
                "start": 56400,
                "end": 58000,
                "part": "а можуть завдати збитків. ",
            },
            {
                "start": 59000,
                "end": 60000,
                "part": "Усього 7 ходів. ",
            },
            {
                "start": 61000,
                "end": 65000,
                "part": "Зробіть перший і дізнаєтеся ...як поведе себе інший гравець.",
            },
        ],
        challengeEnd: [
            {
                "start": 100,
                "end": 2300,
                "part": "Ніколи не знаєш, де знайдеш, ",
            },
            {
                "start": 2400,
                "end": 3700,
                "part": "де втратиш, ",
            },
            {
                "start": 3800,
                "end": 6000,
                "part": "тільки якщо ти не вчений, як я. ",
            },
        ],
        resources: [
            {
                "start": 100,
                "end": 3000,
                "part": "Я працював у Франції, Німеччині, Австрії та Чехії. ",
            },
            {
                "start": 4000,
                "end": 7000,
                "part": "Мені було важливо як найширше розповсюджувати свої ідеї. ",
            },
            {
                "start": 8000,
                "end": 14000,
                "part": "Ви теж чудово попрацювали і настав час маштабувати бізнес на інші країни. ",
            },
            {
                "start": 15000,
                "end": 18000,
                "part": "Але для експансії знадобиться набагато більше ресурсів, ",
            },
            {
                "start": 19000,
                "end": 22000,
                "part": "тому перед вами постає серйозний вибір.",
            },
        ],
        calls: [
            {
                "start": 100,
                "end": 1000,
                "part": "Настав момент, ",
            },
            {
                "start": 1100,
                "end": 4000,
                "part": "коли інтерес до вашої компанії стрімко злетів ",
            },
            {
                "start": 4500,
                "end": 6300,
                "part": "і вам доведеться зробити крок, ",
            },
            {
                "start": 6400,
                "end": 9600,
                "part": "який може змінити вашу компанію та ринок, ",
            },
            {
                "start": 9700,
                "end": 12100,
                "part": "й, можливо, залишити слід в історії. ",
            },
            {
                "start": 12200,
                "end": 14000,
                "part": "Це дуже хвилююче, я знаю. ",
            },
            {
                "start": 14400,
                "end": 19000,
                "part": "Отже, ви сіли за стіл переговорів із дуже великим інвестором. ",
            },
            {
                "start": 19800,
                "end": 24000,
                "part": "Виїжджаючи на переговори, у вас із партнером і командою була домовленість, ",
            },
            {
                "start": 24800,
                "end": 29200,
                "part": "що ви тільки дізнаєтесь про умови, але жодних рішень приймати не будете. ",
            },
            {
                "start": 30800,
                "end": 32600,
                "part": "Однак інвестор вимагає укласти контракт прямо зараз! ",
            },
            {
                "start": 34500,
                "end": 36100,
                "part": "Або контракт піде до вашого прямого конкурента. ",
            },
            {
                "start": 38100,
                "end": 41400,
                "part": "Якщо не зробити цього негайно, ви приречені стати аутсайдером ринку. ",
            },
            {
                "start": 43400,
                "end": 44000,
                "part": "У вас лише 2 хвилини! ",
            },
            {
                "start": 46000,
                "end": 47600,
                "part": "Бажаєте відправити поштових голубів? ",
            },
            {
                "start": 48000,
                "end": 48700,
                "part": "М-м-м... вибачте, зателефонувати? ",
            },
            {
                "start": 50700,
                "end": 52000,
                "part": "Час пішов!",
            },
        ],
        makeMove: [
            {
                "start": 100,
                "end": 4000,
                "part": "Окрім перемог та захоплення фоловерів в інстаграмі... ",
            },
            {
                "start": 4100,
                "end": 6500,
                "part": "Так, шкода, що у мої часи його не було... ",
            },
            {
                "start": 7200,
                "end": 9300,
                "part": "є ще час для критики. ",
            },
            {
                "start": 9400,
                "end": 11600,
                "part": "Ви зіштовхнулися із публічною критикою! ",
            },
            {
                "start": 12800,
                "end": 14300,
                "part": "Причому критикують свої ж ",
            },
            {
                "start": 15000,
                "end": 18000,
                "part": "- за хибне рішення на ринку, погану гру чи невигідну угоду. ",
            },
            {
                "start": 20000,
                "end": 22000,
                "part": "Ех, я б тим критиканам... о-о-о!",
            },
            {
                "start": 23500,
                "end": 24000,
                "part": "Але то я. ",
            },
            {
                "start": 24400,
                "end": 26000,
                "part": "А що зробите ви? ",
            },
        ],
        profit: [
            {
                "start": 100,
                "end": 2300,
                "part": "Світ сповнений можливостей, ",
            },
            {
                "start": 2400,
                "end": 4000,
                "part": "а наука наповнена цифрами. ",
            },
            {
                "start": 5300,
                "end": 6000,
                "part": "Як у вас із математикою, ",
            },
            {
                "start": 7400,
                "end": 8000,
                "part": "перевіремо?",
            },
        ],
        final: [
            {
                "start": 100,
                "end": 1800,
                "part": "Мої вітання! ",
            },
            {
                "start": 1900,
                "end": 6200,
                "part": "Що ж, це було справді цікаво і пізнавально. ",
            },
            {
                "start": 6300,
                "end": 8000,
                "part": "Дякую за компанію. ",
            },
            {
                "start": 8300,
                "end": 13300,
                "part": "Ні-ні, не подумайте... що ви, я не претендую на долю! ",
            },
            {
                "start": 13400,
                "end": 16000,
                "part": "Хоча... попрохаю вас про одну послугу ",
            },
            {
                "start": 16900,
                "end": 19700,
                "part": "- поділитися цієї грою у... Фейсбуці.",
            },
            {
                "start": 21400,
                "end": 24000,
                "part": "До речі, а що таке Фейсбук? ",
            },
        ],
    },
    ru: {
        intro: [
            {
                "start": 100,
                "end": 1100,
                "part": "Приветствую вас.",
            },
            {
                "start": 1200,
                "end": 3200,
                "part": "Я - Луи Пастер. ",
            },
            {
                "start": 3300,
                "end": 8100,
                "part": "Мои заслуги перед человечеством подробно описаны в Википедии. ",
            },
            {
                "start": 8200,
                "end": 9900,
                "part": "А как насчет ваших? ",
            },
            {
                "start": 10000,
                "end": 14200,
                "part": "Давайте узнаем, сможете ли вы остаться в памяти поколений. ",
            },
            {
                "start": 14300,
                "end": 17100,
                "part": "А лучше в рейтинге Форбс. ",
            },
            {
                "start": 17200,
                "end": 19000,
                "part": "Это игра поможет определить, ",
            },
            {
                "start": 19000,
                "end": 21400,
                "part": "какую компанию вы смогли бы построить, ",
            },
            {
                "start": 21500,
                "end": 24200,
                "part": "используя нынешние знания и опыт. ",
            },
            {
                "start": 24300,
                "end": 28900,
                "part": "Это возможность проявить свои бизнес-навыки и определить ценности. ",
            },
            {
                "start": 29000,
                "end": 32200,
                "part": "А перед началом несколько рекомендаций. ",
            },
            {
                "start": 32300,
                "end": 35000,
                "part": "Для игры предпочтительнее использовать компьютер. ",
            },
            {
                "start": 35100,
                "end": 38100,
                "part": "Сосредоточтесь на вопросах и выбирайте ответы, ",
            },
            {
                "start": 38200,
                "end": 41000,
                "part": "которые характеризующий именно вас. ",
            },
            {
                "start": 41100,
                "end": 44700,
                "part": "Учтите, что впоследствии ответы изменить нельзя. ",
            },
            {
                "start": 44800,
                "end": 48900,
                "part": "Да, и бумага, ручка и калькулятор будут не лишними. ",
            },
            {
                "start": 49000,
                "end": 50000,
                "part": "Начнем же игру!",
            },
        ],
        login: [
            {
                "start": 100,
                "end": 2900,
                "part": "Я представился ранее, но повторюсь: ",
            },
            {
                "start": 3000,
                "end": 4700,
                "part": "я - Луи Пастер. ",
            },
            {
                "start": 4800,
                "end": 7000,
                "part": "Приятно познакомиться. ",
            },
        ],
        code: [
            {
                "start": 100,
                "end": 700,
                "part": "Приступим! ",
            },
            {
                "start": 800,
                "end": 1500,
                "part": "Вводите код. ",
            },
            {
                "start": 1800,
                "end": 3000,
                "part": "Смелее!",
            },
        ],
        role: [
            {
                "start": 100,
                "end": 2500,
                "part": "Представьте вашу будущую компанию. ",
            },
            {
                "start": 2600,
                "end": 6400,
                "part": "Да-да, ту самую, о которой скоро напишут в Форбс. ",
            },
            {
                "start": 6500,
                "end": 8800,
                "part": "Сейчас самое время признаться себе, ",
            },
            {
                "start": 8900,
                "end": 12500,
                "part": "какова же ваша роль в этой компании мечты? ",
            },
            {
                "start": 12600,
                "end": 17000,
                "part": "\n Вы со-основатель и вам нужно разделить ответственность с партнером? ",
            },
            {
                "start": 17100,
                "end": 21000,
                "part": "\n Вы лидер и вас вдохновляет работа с командой, где вы участвуете в процессе? ",
            },
            {
                "start": 22000,
                "end": 26000,
                "part": "\n Вы Основатель - визионер. Сильный, энергичный и ведущий за собой.",
            },
        ],
        spheres: [
            {
                "start": 100,
                "end": 2900,
                "part": "Меня всегда интересовали формы самозарождения, ",
            },
            {
                "start": 3000,
                "end": 3400,
                "part": "иммунология и вакцинация. ",
            },
            {
                "start": 5500,
                "end": 8600,
                "part": "Кстати, один из методов назван в мою честь. ",
            },
            {
                "start": 8700,
                "end": 11000,
                "part": "А в какой сфере хотите стать лучшим?",
            },
        ],
        spheresOther: [
            {
                "start": 100,
                "end": 1700,
                "part": "Вы выбрали свой вариант? ",
            },
            {
                "start": 1800,
                "end": 4000,
                "part": "Я заинтригован...",
            }
        ],
        grant: [
            {
                "start": 100,
                "end": 1400,
                "part": "А вот и первое испытание ",
            },
            {
                "start": 1500,
                "end": 3800,
                "part": "серьезности ваших намерений ",
            },
            {
                "start": 3900,
                "end": 7600,
                "part": "– вам предстоит получить грант от государства. ",
            },
            {
                "start": 7700,
                "end": 9400,
                "part": "Но есть условия. ",
            },
            {
                "start": 9500,
                "end": 12750,
                "part": "\n1. Вам необходимо предоставить персональные данные. ",
            },
            {
                "start": 12850,
                "end": 13900,
                "part": "Чем больше данных, ",
            },
            {
                "start": 14000,
                "end": 17100,
                "part": "тем выше вероятность получения гранта. ",
            },
            {
                "start": 17200,
                "end": 19500,
                "part": "\n2. Деньги вы получаете сразу, ",
            },
            {
                "start": 19600,
                "end": 21500,
                "part": "а информацию о себе передаете ",
            },
            {
                "start": 21600,
                "end": 24300,
                "part": "после успешного построения компании.",
            },
            {
                "start": 24400,
                "end": 27400,
                "part": "\n3. Данные следует отправить в телеграм бот, ",
            },
            {
                "start": 27500,
                "end": 30600,
                "part": "ссылку на который получите на последнем экране.",
            },
            {
                "start": 30700,
                "end": 34000,
                "part": "\n4. А если вы \"забудете\" отчитаться государству, ",
            },
            {
                "start": 34100,
                "end": 36700,
                "part": "государство этого не забудет. ",
            },
            {
                "start": 36800,
                "end": 39000,
                "part": "Стоит подвергать свою компанию ",
            },
            {
                "start": 39100,
                "end": 42000,
                "part": "репутациоными рисками и судебным разбирательствам?",
            },
        ],
        motivations: [
            {
                "start": 100,
                "end": 2400,
                "part": "Текущей помощи от государства и накоплений ",
            },
            {
                "start": 2500,
                "end": 5700,
                "part": "хватило для запуска бета-версии продукта и вы убедились, ",
            },
            {
                "start": 5750,
                "end": 7700,
                "part": "что \"дело пахнет миллионами, ",
            },
            {
                "start": 7850,
                "end": 11000,
                "part": "проект получается перспективный и амбициозный\". ",
            },
            {
                "start": 12000,
                "end": 13000,
                "part": "Но любые ресурсы заканчиваются, ",
            },
            {
                "start": 14000,
                "end": 15000,
                "part": "а следом исчезает мотивация команды. ",
            },
            {
                "start": 17000,
                "end": 18000,
                "part": "Вам нужно уделить внимание людям! ",
            },
            {
                "start": 20000,
                "end": 22000,
                "part": "Как вы их будете мотивировать?",
            },
        ],
        investors: [
            {
                "start": 100,
                "end": 1800,
                "part": "Хм! Впечатляет! ",
            },
            {
                "start": 1900,
                "end": 4400,
                "part": "Вы умеете принимать правильные решения. ",
            },
            {
                "start": 4500,
                "end": 6400,
                "part": "Вами заинтересовались инвесторы, ",
            },
            {
                "start": 6500,
                "end": 10700,
                "part": "которые готовы предоставить средства для запуска продукта на рынок. ",
            },
            {
                "start": 10800,
                "end": 13000,
                "part": "Кого из инвесторов вы предпочтете?",
            },
        ],
        investorsOther: [
            {
                "start": 100,
                "end": 1400,
                "part": "Вы выбрали свой вариант! ",
            },
            {
                "start": 1500,
                "end": 3000,
                "part": "Интригующе...",
            }
        ],
        noInvestorSteveJobs: [
            {
                "start": 100,
                "end": 2200,
                "part": "Да, Стив - удивительный человек! ",
            },
            {
                "start": 2300,
                "end": 5000,
                "part": "Хотя при жизни нам и не удалось поработать вместе. ",
            },
        ],
        challenge: [
            {
                "start": 100,
                "end": 1900,
                "part": "Вспоминаю свои успехи... ",
            },
            {
                "start": 2000,
                "end": 5800,
                "part": "Множество ученых работали в том же направлении, что и я, ",
            },
            {
                "start": 5900,
                "end": 7900,
                "part": "но их имена уже забыты.",
            },
            {
                "start": 8000,
                "end": 9000,
                "part": "К чему это я?!... ",
            },
            {
                "start": 9100,
                "end": 10900,
                "part": "У вас все идет как следует! ",
            },
            {
                "start": 11000,
                "end": 13950,
                "part": "Вы запустили продукт и начали активные продажи. ",
            },
            {
                "start": 14050,
                "end": 15900,
                "part": "Ваша доля на рынке растет. ",
            },
            {
                "start": 16000,
                "end": 18300,
                "part": "Но... ваша компания, увы, ",
            },
            {
                "start": 18400,
                "end": 21100,
                "part": "не единственная, кто зарабатывает в выбранной нише. ",
            },
            {
                "start": 21200,
                "end": 22700,
                "part": "Есть и другие. ",
            },
            {
                "start": 22800,
                "end": 29300,
                "part": "Каждое ваше действие в совокупности с действиями других игроков может создавать возможности для всех, ",
            },
            {
                "start": 29450,
                "end": 33900,
                "part": "а может дать вам временное преимущество или сделать уязвимым. ",
            },
            {
                "start": 34000,
                "end": 37400,
                "part": "Нужно продолжать зарабатывать, чтобы развивать свой продукт. ",
            },
            {
                "start": 37500,
                "end": 40600,
                "part": "Внимательно изучите текущие правила на рынке: ",
            },
            {
                "start": 40700,
                "end": 45400,
                "part": "4 комбинации ваших возможных действий и действий других игроков. ",
            },
            {
                "start": 45500,
                "end": 48000,
                "part": "Комбинации могут приумножить ваш заработок... ",
            },
            {
                "start": 48100,
                "end": 50500,
                "part": "а могут принести убытки. ",
            },
            {
                "start": 50600,
                "end": 52000,
                "part": "Всего 7 ходов. ",
            },
            {
                "start": 52100,
                "end": 56000,
                "part": "Сделайте первый и узнайте, как поведет себя другой игрок...",
            },
        ],
        challengeEnd: [
            {
                "start": 100,
                "end": 2300,
                "part": "Никогда не знаешь, где найдешь, ",
            },
            {
                "start": 2400,
                "end": 3700,
                "part": "где потеряешь, ",
            },
            {
                "start": 3800,
                "end": 6000,
                "part": "если только ты не ученый, как я. ",
            },
        ],
        resources: [
            {
                "start": 100,
                "end": 3500,
                "part": "Я работал во Франции, в Германии, Австрии и Чехии. ",
            },
            {
                "start": 3600,
                "end": 7200,
                "part": "Мне было важно как можно шире распространить свои идеи. ",
            },
            {
                "start": 7300,
                "end": 12400,
                "part": "Вы тоже прекрасно поработали и пора масштабировать бизнес на другие страны. ",
            },
            {
                "start": 12500,
                "end": 16700,
                "part": "Но для экспансии нужно несоизмеримо больше ресурсов, ",
            },
            {
                "start": 16800,
                "end": 17000,
                "part": "поэтому вам предстоит серьезный выбор.",
            },
        ],
        calls: [
            {
                "start": 100,
                "end": 900,
                "part": "Наступил момент, ",
            },
            {
                "start": 1000,
                "end": 4500,
                "part": "когда интерес к вашей компании стремительно взлетел ",
            },
            {
                "start": 4600,
                "end": 6300,
                "part": "и вам придется сделать шаг, ",
            },
            {
                "start": 6400,
                "end": 9600,
                "part": "который может изменить вашу компанию и рынок, ",
            },
            {
                "start": 9700,
                "end": 12100,
                "part": "и, возможно, оставит след в истории. ",
            },
            {
                "start": 12200,
                "end": 15400,
                "part": "Это очень волнительно, я знаю. ",
            },
            {
                "start": 15500,
                "end": 20000,
                "part": "Итак, вы сели за стол переговоров с очень крупным инвестором. ",
            },
            {
                "start": 20100,
                "end": 24000,
                "part": "Уезжая на переговоры, у вас с партнером и командой была договоренность, ",
            },
            {
                "start": 24100,
                "end": 29200,
                "part": "что вы только узнаете условия и никаких решений принимать не будете. ",
            },
            {
                "start": 29300,
                "end": 32600,
                "part": "Однако инвестор требует заключить контракт прямо сейчас! ",
            },
            {
                "start": 32700,
                "end": 36100,
                "part": "Либо контракт уйдет к вашему прямому конкуренту. ",
            },
            {
                "start": 36200,
                "end": 41400,
                "part": "Если не сделать этого немедленно, вы обречены стать аутсайдером рынка. ",
            },
            {
                "start": 41500,
                "end": 43400,
                "part": "У вас ровно 2 минуты! ",
            },
            {
                "start": 43500,
                "end": 45600,
                "part": "Хотите отправить почтовых голубей? ",
            },
            {
                "start": 45700,
                "end": 47700,
                "part": "М-м-м... простите, позвонить? ",
            },
            {
                "start": 47800,
                "end": 49000,
                "part": "Время пошло!",
            },
        ],
        makeMove: [
            {
                "start": 100,
                "end": 4000,
                "part": "Кроме побед и восхищения окружающих в инстаграмме... ",
            },
            {
                "start": 4100,
                "end": 6500,
                "part": "М да, жаль в мое время его не было... ",
            },
            {
                "start": 6600,
                "end": 9300,
                "part": "есть еще время для критики. ",
            },
            {
                "start": 9400,
                "end": 11600,
                "part": "Вы столкнулись с публичной критикой! ",
            },
            {
                "start": 11700,
                "end": 13300,
                "part": "Причем критикуют свои же ",
            },
            {
                "start": 13400,
                "end": 15300,
                "part": "- за неверное решение на рынке. ",
            },
            {
                "start": 15450,
                "end": 19000,
                "part": "Ех, я бы этим критиканам... о-о-о!",
            },
            {
                "start": 19100,
                "end": 20000,
                "part": "Но это я. ",
            },
            {
                "start": 20100,
                "end": 21000,
                "part": "А что предпримете вы? ",
            },
        ],
        profit: [
            {
                "start": 100,
                "end": 2300,
                "part": "Мир полон возможностей, ",
            },
            {
                "start": 2400,
                "end": 4000,
                "part": "а наука полна цифр. ",
            },
            {
                "start": 4100,
                "end": 5700,
                "part": "Как у вас с математикой, ",
            },
            {
                "start": 5800,
                "end": 6000,
                "part": "проверим?",
            },
        ],
        final: [
            {
                "start": 100,
                "end": 1800,
                "part": "Мои поздравления! ",
            },
            {
                "start": 1900,
                "end": 5400,
                "part": "Что ж, это было поистине увлекательно и познавательно. ",
            },
            {
                "start": 5500,
                "end": 7200,
                "part": "Благодарю за компанию. ",
            },
            {
                "start": 7250,
                "end": 11300,
                "part": "Нет-нет, не подумайте... что вы, я не претендую на долю! ",
            },
            {
                "start": 11400,
                "end": 13700,
                "part": "Хотя... попрошу вас об одной услуге ",
            },
            {
                "start": 13800,
                "end": 16700,
                "part": "- поделиться это игрой в... Фейс-бук.",
            },
            {
                "start": 16800,
                "end": 19000,
                "part": "Кстати, а что это, фейсбук? ",
            },
        ],
    },
    es: {
        intro: [
            {
                "start": 100,
                "end": 1100,
                "part": "Saludos.",
            },
            {
                "start": 1200,
                "end": 2500,
                "part": "Soy Luis Pasteur. ",
            },
            {
                "start": 2600,
                "end": 6900,
                "part": "Mis servicios a la humanidad se describen en detalle en Wikipedia. ",
            },
            {
                "start": 7000,
                "end": 8300,
                "part": "¿Y que hay del suyo? ",
            },
            {
                "start": 8400,
                "end": 12400,
                "part": "Averigüemos si puedes permanecer en la memoria de generaciones. ",
            },
            {
                "start": 12500,
                "end": 15000,
                "part": "Y mejor en la calificación de Forbes. ",
            },
            {
                "start": 15100,
                "end": 17000,
                "part": "Este juego le ayudará a determinar ",
            },
            {
                "start": 17100,
                "end": 20000,
                "part": "qué tipo de empresa podría construir utilizando ",
            },
            {
                "start": 20100,
                "end": 22900,
                "part": "su conocimiento y experiencia actuales. ",
            },
            {
                "start": 23000,
                "end": 28900,
                "part": "Esta es una oportunidad para mostrar sus habilidades comerciales y definir sus valores. ",
            },
            {
                "start": 29000,
                "end": 32200,
                "part": "Y antes de empezar, algunas recomendaciones. ",
            },
            {
                "start": 32300,
                "end": 36500,
                "part": "Entonces… Para el juego es preferible usar una computadora. ",
            },
            {
                "start": 36600,
                "end": 41900,
                "part": "Enfócate en las preguntas y elige las respuestas que te caracterizan. ",
            },
            {
                "start": 42000,
                "end": 45900,
                "part": "Tenga en cuenta que las respuestas no se pueden cambiar más tarde. ",
            },
            {
                "start": 46000,
                "end": 51500,
                "part": "Sí, y el papel, el bolígrafo y la calculadora no serán superfluos. ",
            },
            {
                "start": 51500,
                "end": 53000,
                "part": "¡Empecemos el juego! ",
            },
        ],
        login: [
            {
                "start": 100,
                "end": 2500,
                "part": "Me presenté antes, pero repito: ",
            },
            {
                "start": 2600,
                "end": 3500,
                "part": "soy Louis. ",
            },
            {
                "start": 3600,
                "end": 5000,
                "part": "Encantada de conocerte. ",
            },
        ],
        code: [
            {
                "start": 100,
                "end": 700,
                "part": "¡Empecemos! ",
            },
            {
                "start": 800,
                "end": 1500,
                "part": "Introduzca el código ",
            },
            {
                "start": 1800,
                "end": 3000,
                "part": "¡Más valentia!",
            },
        ],
        role: [
            {
                "start": 100,
                "end": 2400,
                "part": "Imagina tu futura empresa. ",
            },
            {
                "start": 2500,
                "end": 6500,
                "part": "Sí, sí, la mismísima que pronto se escribirá en Forbes. ",
            },
            {
                "start": 6600,
                "end": 9500,
                "part": "Ahora es el momento de admitirte a ti mismo, ",
            },
            {
                "start": 9600,
                "end": 12500,
                "part": "¿cuál es tu papel en esta empresa de ensueño? ",
            },
            {
                "start": 12600,
                "end": 17300,
                "part": "\n ¿Eres cofundador y necesitas compartir la responsabilidad con un socio? ",
            },
            {
                "start": 17400,
                "end": 23200,
                "part": "\n ¿Eres un líder e inspirado por trabajar con un equipo, dónde estás involucrado en el proceso? ",
            },
            {
                "start": 23300,
                "end": 28000,
                "part": "\n Eres el Fundador - el visionario. Fuerte, enérgico y líder.",
            },
        ],
        spheres: [
            {
                "start": 100,
                "end": 4000,
                "part": "Siempre me han interesado las formas de generación espontánea, ",
            },
            {
                "start": 4100,
                "end": 6600,
                "part": "la inmunología y la vacunación. ",
            },
            {
                "start": 6700,
                "end": 9300,
                "part": "Por cierto, uno de los métodos lleva mi nombre. ",
            },
            {
                "start": 9400,
                "end": 11000,
                "part": "¿En qué área quieres ser el mejor?",
            },
        ],
        spheresOther: [
            {
                "start": 100,
                "end": 1700,
                "part": "¿Has elegido tu opción?  ",
            },
            {
                "start": 1800,
                "end": 4000,
                "part": "Estoy intrigado…",
            }
        ],
        grant: [
            {
                "start": 100,
                "end": 1900,
                "part": "Y aquí está la primera prueba ",
            },
            {
                "start": 2000,
                "end": 4100,
                "part": "de la seriedad de sus intenciones: ",
            },
            {
                "start": 4200,
                "end": 6900,
                "part": " debe recibir una subvención del estado. ",
            },
            {
                "start": 7000,
                "end": 8800,
                "part": "Pero hay condiciones. ",
            },
            {
                "start": 8900,
                "end": 11900,
                "part": "\n1. Debe proporcionar datos personales. ",
            },
            {
                "start": 12000,
                "end": 12800,
                "part": "Cuantos más datos, ",
            },
            {
                "start": 12900,
                "end": 16400,
                "part": "mayor es la probabilidad de recibir una subvención. ",
            },
            {
                "start": 16600,
                "end": 18200,
                "part": "\n2. Recibirá el dinero de inmediato ",
            },
            {
                "start": 18300,
                "end": 20000,
                "part": "y transferirá información sobre ",
            },
            {
                "start": 20100,
                "end": 24000,
                "part": "usted después de la construcción exitosa de la empresa.",
            },
            {
                "start": 24100,
                "end": 26700,
                "part": "\n3. Los datos deben enviarse al bot de Telegram, ",
            },
            {
                "start": 26800,
                "end": 30100,
                "part": "cuyo enlace recibirá en la última pantalla.",
            },
            {
                "start": 30200,
                "end": 32600,
                "part": "\n4. Y si \"olvida\" informar al estado, ",
            },
            {
                "start": 32700,
                "end": 34800,
                "part": "el estado no lo olvidará. ",
            },
            {
                "start": 34900,
                "end": 37000,
                "part": "¿Vale la pena exponer a su empresa ",
            },
            {
                "start": 37100,
                "end": 40000,
                "part": "a riesgos reputacionales y litigios?",
            },
        ],
        motivations: [
            {
                "start": 100,
                "end": 2850,
                "part": "La ayuda actual del Estado y los ahorros fueron ",
            },
            {
                "start": 2950,
                "end": 6250,
                "part": "suficientes para lanzar la versión beta del producto, ",
            },
            {
                "start": 6350,
                "end": 10000,
                "part": "y se mostró convencido de que “el negocio huele a millones, ",
            },
            {
                "start": 10100,
                "end": 13000,
                "part": "el proyecto es prometedor y ambicioso”. ",
            },
            {
                "start": 13100,
                "end": 14500,
                "part": "Pero los recursos se agotan ",
            },
            {
                "start": 14600,
                "end": 17700,
                "part": "y luego desaparece la motivación del equipo. ",
            },
            {
                "start": 17800,
                "end": 20600,
                "part": "¡Tienes que prestar atención a la gente! ",
            },
            {
                "start": 20700,
                "end": 22000,
                "part": "¿Cómo los motivarás?",
            },
        ],
        investors: [
            {
                "start": 100,
                "end": 2500,
                "part": "¡Mmm! ¡Impresionante! ",
            },
            {
                "start": 2600,
                "end": 5400,
                "part": "Sabes tomar las decisiones correctas. ",
            },
            {
                "start": 5500,
                "end": 7800,
                "part": "Los inversores que están dispuestos a proporcionar ",
            },
            {
                "start": 7900,
                "end": 12500,
                "part": "fondos para lanzar el producto al mercado están interesados en usted. ",
            },
            {
                "start": 12600,
                "end": 14000,
                "part": "¿Qué inversor preferirías?",
            },
        ],
        investorsOther: [
            {
                "start": 100,
                "end": 1800,
                "part": "¿Has elegido tu opción? ",
            },
            {
                "start": 1900,
                "end": 3000,
                "part": "Estoy intrigado…",
            }
        ],
        noInvestorSteveJobs: [
            {
                "start": 100,
                "end": 2700,
                "part": "¡Sí, Steve es una persons increíble! ",
            },
            {
                "start": 2800,
                "end": 6000,
                "part": "Aunque durante nuestra vida no logramos trabajar juntos. ",
            },
        ],
        challenge: [
            {
                "start": 100,
                "end": 1900,
                "part": "Recuerdo mis éxitos... ",
            },
            {
                "start": 2000,
                "end": 5300,
                "part": "Muchos científicos trabajaron en la misma dirección que yo, ",
            },
            {
                "start": 5400,
                "end": 7900,
                "part": "pero sus nombres ya han sido olvidados.",
            },
            {
                "start": 8000,
                "end": 9500,
                "part": "¡¿Por qué estoy?!... ",
            },
            {
                "start": 9600,
                "end": 11000,
                "part": "¡Todo te va bien! ",
            },
            {
                "start": 11100,
                "end": 14650,
                "part": "Ha lanzado un producto y ha iniciado ventas activas. ",
            },
            {
                "start": 14750,
                "end": 17000,
                "part": "Su cuota de mercado está creciendo. ",
            },
            {
                "start": 17100,
                "end": 19100,
                "part": "Pero... tu empresa, por desgracia, ",
            },
            {
                "start": 19200,
                "end": 22300,
                "part": "no es la única que gana dinero en el nicho elegido. ",
            },
            {
                "start": 22400,
                "end": 23400,
                "part": "Hay otros. ",
            },
            {
                "start": 23500,
                "end": 30000,
                "part": "Cada una de tus acciones, combinada con las acciones de otros jugadores, puede crear oportunidades para todos, ",
            },
            {
                "start": 30100,
                "end": 34500,
                "part": "o puede darte una ventaja temporal o hacerte vulnerable. ",
            },
            {
                "start": 34600,
                "end": 37800,
                "part": "Necesitas seguir ganando para desarrollar tu producto. ",
            },
            {
                "start": 37900,
                "end": 41600,
                "part": "Estudia detenidamente las reglas actuales del mercado: ",
            },
            {
                "start": 41700,
                "end": 47100,
                "part": "4 combinaciones de tus posibles acciones y las acciones de otros jugadores. ",
            },
            {
                "start": 47200,
                "end": 50000,
                "part": "Las combinaciones pueden multiplicar sus ganancias... ",
            },
            {
                "start": 50100,
                "end": 52200,
                "part": "o pueden causar pérdidas. ",
            },
            {
                "start": 52300,
                "end": 54400,
                "part": "Solo 7 movimientos. ",
            },
            {
                "start": 54500,
                "end": 58000,
                "part": "Haz el primero y observa cómo se comporta el otro jugador...",
            },
        ],
        challengeEnd: [
            {
                "start": 100,
                "end": 2300,
                "part": "Nunca sabes dónde lo encontrarás,  ",
            },
            {
                "start": 2400,
                "end": 4000,
                "part": "dónde lo perderás, ",
            },
            {
                "start": 4100,
                "end": 6000,
                "part": "a menos que seas un científico como yo. ",
            },
        ],
        resources: [
            {
                "start": 100,
                "end": 5000,
                "part": "He trabajado en Francia, Alemania, Austria y República Checa. ",
            },
            {
                "start": 5100,
                "end": 9500,
                "part": "Era importante para mí difundir mis ideas lo más ampliamente posible. ",
            },
            {
                "start": 9600,
                "end": 14200,
                "part": "También hiciste un gran trabajo y es hora de escalar tu negocio a otros países. ",
            },
            {
                "start": 14300,
                "end": 18100,
                "part": "Pero la expansión requiere desproporcionadamente más recursos, ",
            },
            {
                "start": 18200,
                "end": 21000,
                "part": "por lo que debe tomar una decisión seria.",
            },
        ],
        calls: [
            {
                "start": 100,
                "end": 1500,
                "part": "Ha llegado el momento ",
            },
            {
                "start": 1600,
                "end": 4900,
                "part": "en que el interés por tu empresa se disparó y tendrás que dar",
            },
            {
                "start": 5000,
                "end": 8300,
                "part": "un paso que puede cambiar tu empresa y el mercado, ",
            },
            {
                "start": 8400,
                "end": 10900,
                "part": "y quizás dejar una huella en la historia. ",
            },
            {
                "start": 11000,
                "end": 13600,
                "part": "Es muy emocionante, lo sé. ",
            },
            {
                "start": 13700,
                "end": 18300,
                "part": "Entonces, te sentaste a la mesa de negociaciones con un gran inversionista. ",
            },
            {
                "start": 18400,
                "end": 20050,
                "part": "Al partir para las negociaciones, ",
            },
            {
                "start": 20150,
                "end": 22600,
                "part": "tenía un acuerdo con su socio y el equipo de que ",
            },
            {
                "start": 22700,
                "end": 26500,
                "part": "solo averiguaría las condiciones y no tomaría ninguna decisión. ",
            },
            {
                "start": 26600,
                "end": 31200,
                "part": "¡Sin embargo, el inversor exige concluir un contrato ahora mismo! ",
            },
            {
                "start": 31300,
                "end": 34100,
                "part": "O el contrato irá a su competidor directo. ",
            },
            {
                "start": 34200,
                "end": 40250,
                "part": "Si no hace esto de inmediato, está condenado a convertirse en un extraño del mercado. ",
            },
            {
                "start": 40350,
                "end": 43000,
                "part": "¡Tienes exactamente 2 minutos! ",
            },
            {
                "start": 43100,
                "end": 45600,
                "part": "¿Te gustaría enviar palomas mensajeras? ",
            },
            {
                "start": 45700,
                "end": 48200,
                "part": "Mmm... disculpe, ¿llamar? ",
            },
            {
                "start": 48300,
                "end": 50000,
                "part": "¡Como pasa  el tiempo!",
            },
        ],
        makeMove: [
            {
                "start": 100,
                "end": 4000,
                "part": "Además de las victorias y la admiración de los demás en Instagram… ",
            },
            {
                "start": 4100,
                "end": 6500,
                "part": "Bueno, lástima que en mi época no estaba… ",
            },
            {
                "start": 6600,
                "end": 9100,
                "part": "todavía hay tiempo para las críticas. ",
            },
            {
                "start": 9200,
                "end": 11600,
                "part": "¡Te has enfrentado a la crítica pública! ",
            },
            {
                "start": 11700,
                "end": 14100,
                "part": "Además, critican tus personas: ",
            },
            {
                "start": 14200,
                "end": 19300,
                "part": "por una decisión equivocada en el mercado, un mal juego o un mal trato. ",
            },
            {
                "start": 19450,
                "end": 22500,
                "part": "¡oh-oh-oh! Eh, quisiera estos críticos... ",
            },
            {
                "start": 22600,
                "end": 23500,
                "part": "Pero soy yo. ",
            },
            {
                "start": 23600,
                "end": 24000,
                "part": "¿Qué vas a hacer? ",
            },
        ],
        profit: [
            {
                "start": 100,
                "end": 2450,
                "part": "El mundo está lleno de posibilidades ",
            },
            {
                "start": 2550,
                "end": 4600,
                "part": "y la ciencia está llena de números. ",
            },
            {
                "start": 4700,
                "end": 7000,
                "part": "¿Cómo estás con las matemáticas, ",
            },
            {
                "start": 7100,
                "end": 8000,
                "part": "échale un vistazo?",
            },
        ],
        final: [
            {
                "start": 100,
                "end": 1800,
                "part": "¡Felicidades! ",
            },
            {
                "start": 1900,
                "end": 5400,
                "part": "Bueno, fue realmente fascinante y educativo. ",
            },
            {
                "start": 5500,
                "end": 7200,
                "part": "Gracias por la compañía. ",
            },
            {
                "start": 7250,
                "end": 10600,
                "part": "No, no, no pienses… ¡qué te reclamo una parte! ",
            },
            {
                "start": 10700,
                "end": 12800,
                "part": "Aunque... te pediré un favor: ",
            },
            {
                "start": 12900,
                "end": 15600,
                "part": "compartir este juego en... Facebook. ",
            },
            {
                "start": 15700,
                "end": 19000,
                "part": "Por cierto, ¿qué es Facebook?",
            },
        ],
    }
}

export default subtitles;
