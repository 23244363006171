import audioIntroMp3 from "@assets/audios/ua/mp3/intro.mp3";
import audioLoginMp3 from "@assets/audios/ua/mp3/login.mp3";
import audioCodeMp3 from "@assets/audios/ua/mp3/code.mp3";
import audioRoleMp3 from "@assets/audios/ua/mp3/role.mp3";
import audioSpheresMp3 from "@assets/audios/ua/mp3/spheres.mp3";
import audioSpheresOtherMp3 from "@assets/audios/ua/mp3/spheres-other.mp3";
import audioGrantMp3 from "@assets/audios/ua/mp3/grant.mp3";
import audioMotivationsMp3 from "@assets/audios/ua/mp3/motivations.mp3";
import audioInvestorsMp3 from "@assets/audios/ua/mp3/investors.mp3";
import audioInvestorSteveJobsMp3 from "@assets/audios/ua/mp3/investorSteveJobs.mp3";
import audioInvestorsOtherMp3 from "@assets/audios/ua/mp3/investors-other.mp3";
import audioChallengeMp3 from "@assets/audios/ua/mp3/challenge.mp3";
import audioChallengeEndMp3 from "@assets/audios/ua/mp3/challenge-end.mp3";
import audioResourcesMp3 from "@assets/audios/ua/mp3/resources.mp3";
import audioCallsMp3 from "@assets/audios/ua/mp3/calls.mp3";
import audioMakeMoveMp3 from "@assets/audios/ua/mp3/make-move.mp3";
import audioProfitMp3 from "@assets/audios/ua/mp3/profit.mp3";
import audioFinalMp3 from "@assets/audios/ua/mp3/final.mp3";
import seoCallMp3 from "@assets/audios/ua/mp3/seo-call.mp3";
import teamCallMp3 from "@assets/audios/ua/mp3/team-call.mp3";
import partnerCallMp3 from "@assets/audios/ua/mp3/partner-call.mp3";
import audioIntroWav from "@assets/audios/ua/wav/intro.wav";
import audioLoginWav from "@assets/audios/ua/wav/login.wav";
import audioCodeWav from "@assets/audios/ua/wav/code.wav";
import audioRoleWav from "@assets/audios/ua/wav/role.wav";
import audioSpheresWav from "@assets/audios/ua/wav/spheres.wav";
import audioSpheresOtherWav from "@assets/audios/ua/wav/spheres-other.wav";
import audioGrantWav from "@assets/audios/ua/wav/grant.wav";
import audioMotivationsWav from "@assets/audios/ua/wav/motivations.wav";
import audioInvestorsWav from "@assets/audios/ua/wav/investors.wav";
import audioInvestorSteveJobsWav from "@assets/audios/ua/wav/investorSteveJobs.wav";
import audioInvestorsOtherWav from "@assets/audios/ua/wav/investors-other.wav";
import audioChallengeWav from "@assets/audios/ua/wav/challenge.wav";
import audioChallengeEndWav from "@assets/audios/ua/wav/challenge-end.wav";
import audioResourcesWav from "@assets/audios/ua/wav/resources.wav";
import audioCallsWav from "@assets/audios/ua/wav/calls.wav";
import audioMakeMoveWav from "@assets/audios/ua/wav/make-move.wav";
import audioProfitWav from "@assets/audios/ua/wav/profit.wav";
import audioFinalWav from "@assets/audios/ua/wav/final.wav";
import seoCallWav from "@assets/audios/ua/wav/seo-call.wav";
import teamCallWav from "@assets/audios/ua/wav/team-call.wav";
import partnerCallWav from "@assets/audios/ua/wav/partner-call.wav";

export const ua = {
    audioIntroMp3,
    audioLoginMp3,
    audioCodeMp3,
    audioRoleMp3,
    audioSpheresMp3,
    audioSpheresOtherMp3,
    audioGrantMp3,
    audioMotivationsMp3,
    audioInvestorsMp3,
    audioInvestorSteveJobsMp3,
    audioInvestorsOtherMp3,
    audioChallengeMp3,
    audioChallengeEndMp3,
    audioResourcesMp3,
    audioCallsMp3,
    audioMakeMoveMp3,
    audioProfitMp3,
    audioFinalMp3,
    seoCallMp3,
    teamCallMp3,
    partnerCallMp3,
    audioIntroWav,
    audioLoginWav,
    audioCodeWav,
    audioRoleWav,
    audioSpheresWav,
    audioSpheresOtherWav,
    audioGrantWav,
    audioMotivationsWav,
    audioInvestorsWav,
    audioInvestorSteveJobsWav,
    audioInvestorsOtherWav,
    audioChallengeWav,
    audioChallengeEndWav,
    audioResourcesWav,
    audioCallsWav,
    audioMakeMoveWav,
    audioProfitWav,
    audioFinalWav,
    seoCallWav,
    teamCallWav,
    partnerCallWav
}
