import {memo, useContext, useMemo, useState} from "react";
import {Button, Checkbox, InfoList, InfoTime, Preloader, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {addGrantToMoney} from "@/actionCreators";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {userAnswerRequest} from "@/api/request";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameGrant = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const [chance, setChance] = useState(0);
    const [ageeCheck, setAgeeCheck] = useState(false);
    const [status, setStatus] = useState({
        send_picture: 0,
        send_selfie: 0,
        send_text: 0,
        send_birthday: 0,
        share_facebook: 0
    });
    const quizText = useMemo(() => [
        t("Self-Portrait Drawing"),
        t("Camera Selfies"),
        t("Two paragraphs of text about yourself"),
        t("Date of birth"),
        t("Share the game on facebook"),
    ], []);

    const handleChange = (checked, setChecked, id) => {
        const keyArray = Object.keys(status);
        const key = keyArray[id];
        setChecked(!checked);
        setStatus({...status, [key]: !checked ? 1 : 0})
        setChance(chance => {
            if (!checked) {
                return chance + 20
            } else {
                return chance - 20
            }
        })

        // const moneyPrize = 10000;
        // !checked ? dispatch(addGrantMoney(moneyPrize)) : dispatch(removeGrantMoney(moneyPrize));
    };

    const handleAgeed = () => {
        setAgeeCheck(!ageeCheck);
    };

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioGrantWav, mp3: audios[lang].audioGrantMp3}}
                               subtitle={subtitles[lang].grant}
                               autoplay={true}/>
                        <Title>{t("Check the items you are willing to provide for the grant")}</Title>
                        <QuizList>
                            {quizText.map((text, i) => (
                                <li key={i}>
                                    <Checkbox handleChange={handleChange} id={i} text={text}/>
                                </li>
                            ))}
                        </QuizList>
                        <p className="left-side__info">{t("Note: Information that you are willing to provide, you need to send yourself to @telegram within 10 minutes after the end of the game, the link to @telegram and fb will appear on the last screen.")}</p>
                        <p className="left-side__info">
                            <Checkbox checkStatus={ageeCheck} handleChange={handleAgeed} text={t("Agreed")}/>
                        </p>
                        <Button
                            handleClick={() => {
                                dispatch(userAnswerRequest({
                                    id: userID,
                                    ...status
                                }))
                                dispatch(addGrantToMoney());
                            }}
                            disabled={!ageeCheck}
                            className="btn btn-primary">{t("Next")}</Button>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--room">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                <div className="right-side__grant">
                    <p className="right-side__grant-title">{t("Chance of getting a grant")}</p>
                    <p className="right-side__grant-info">
                        {chance}
                        <span>&#37;</span>
                    </p>
                </div>
            </RightSide>
        </div>
    );
};

export default memo(GameGrant);
