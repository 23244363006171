import {Button, FormControl} from "@/components";
import {userAnswerRequest} from "@/api/request";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {gameTimeSelector, userIDSelector} from "@/selectors";
import {deleteUnderscore, getTime} from "@/utils";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

const MathScreenOne = ({profitClickRef, handleProfitChange}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const time = useSelector(gameTimeSelector);
    const [profitInput, setProfitInput] = useState({text: "___ K", helper: "", error: false, showMask: true});
    const profitRef = useRef(null);
    const btnRef = useRef(null);
    const disabledBtn = !deleteUnderscore(profitInput.text);

    useEffect(() => {
        profitRef.current.focus();
    }, []);

    return (
        <>
            <p className="left-side__text">
                <span>{t("You bought a promising startup for 15K.")}</span>
                <span>{t("Some time later sold it for 27K.")}</span>
                <span>{t("Two years later they bought the same company again for 42K.")}</span>
                <span>{t("And a year later they sold it for 100K.")}</span>
                <span>{t("What profit did you make on the basis of all your transactions?")}</span>
            </p>
            <FormControl label={t("Enter answer")} info={profitInput} helper="">
                <InputMask alwaysShowMask={true}
                           className="form-control__input"
                           value={profitInput.text}
                           onChange={(e) => {
                               handleProfitChange(profitInput, setProfitInput, e)
                           }}
                           mask="999 K">
                    {inputProps =>
                        <input
                            ref={profitRef}
                            onKeyUp={(e) => {
                                profitClickRef(profitInput, profitRef, btnRef, setProfitInput, e)
                            }}
                            {...inputProps}
                        />}
                </InputMask>
            </ FormControl>
            <Button
                disabled={disabledBtn}
                handleClick={() => dispatch(userAnswerRequest({
                    id: userID,
                    number_task: 1,
                    task_answer: deleteUnderscore(profitInput.text),
                    spent_time: getTime(time)
                }))}
                innerRef={btnRef}
                className="btn btn-primary">{t("Next")}</Button>
        </>
    )
}

MathScreenOne.propTypes = {
    profitClickRef: PropTypes.func.isRequired,
    handleProfitChange: PropTypes.func.isRequired
};

export default MathScreenOne;
