import PropTypes from "prop-types";
import "./Info.scss";

const InfoList = ({children}) => {
    return <ul className="info__list">{children}</ul>;
};

InfoList.propTypes = {
    children: PropTypes.node.isRequired,
};

export default InfoList;
