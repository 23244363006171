import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

const AudioProgressBar =  ({ percentage = 0, onChange }) => {
    const [position, setPosition] = useState(0);
    const [marginLeft, setMarginLeft] = useState(0);
    const [progressBarWidth, setProgressBarWidth] = useState(0);

    const rangeRef = useRef();
    const thumbRef = useRef();

    useEffect(() => {
        const rangeWidth = rangeRef.current?.getBoundingClientRect().width;
        const thumbWidth = thumbRef.current?.getBoundingClientRect().width;
        const centerThumb = (thumbWidth / 100) * percentage * -1;
        const centerProgressBar =
            thumbWidth + (rangeWidth / 100) * percentage - (thumbWidth / 100) * percentage;
        setPosition(percentage);
        setMarginLeft(centerThumb);
        setProgressBarWidth(centerProgressBar);
    }, [percentage]);

    return (
        <div className='audio__progressbar'>
            <div
                className='audio__progressbar-cover'
                style={{
                    width: `${progressBarWidth}px`
                }}
            />
            <div
                className='audio__progressbar-thumb'
                ref={thumbRef}
                style={{
                    left: `${position}%`,
                    marginLeft: `${marginLeft}px`
                }}
            />
            <input
                type='range'
                value={position}
                ref={rangeRef}
                step='0.01'
                className='audio__progressbar-range'
                onChange={onChange}
                tabIndex="-1"
            />
        </div>
    )
}

AudioProgressBar.propTypes = {
    percentage: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default AudioProgressBar;