import axios from "axios";
import {
    ADD_ANSWER,
    ADD_ANSWER_GAME, ADD_CODE,
    ADD_USER, CHECK_RESPONSE,
    GAME_FINAL_SCREEN,
    GAME_LOGIC,
    SEND_CODE
} from "@/api/path";
import {
    FAIL,
    PAGE_ADD_API_RESPONSE, PAGE_ADD_CODE,
    PAGE_ADD_GAME_ANSWER,
    PAGE_ADD_GAME_LOGIC,
    PAGE_ADD_USER_ANSWER,
    PAGE_GET_FINAL_SCREEN,
    PAGE_SEND_CODE,
    START,
    SUCCESS
} from "@/constants";
import {addGameMove} from "@/actionCreators";

const loginRequest = (schema, step) => (dispatch) => {
    dispatch({type: PAGE_ADD_API_RESPONSE + START})
    return axios.post(ADD_USER, schema)
        .then(response => dispatch({
            type: PAGE_ADD_API_RESPONSE + SUCCESS,
            payload: [response.data, step]
        }))
        .catch(error => dispatch({
            type: PAGE_ADD_API_RESPONSE + FAIL,
            payload: [error.response.data, step]
        }))
};

const userAnswerRequest = (schema) => (dispatch) => {
    dispatch({type: PAGE_ADD_USER_ANSWER + START})
    return axios.post(ADD_ANSWER, schema)
        .then(response => dispatch({
            type: PAGE_ADD_USER_ANSWER + SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: PAGE_ADD_USER_ANSWER + FAIL,
            payload: error.response.data
        }))
};

const gameLogicRequest = (schema, user, answer) => (dispatch) => {
    dispatch({type: PAGE_ADD_GAME_LOGIC + START})
    return axios.post(GAME_LOGIC, schema)
        .then(response => {
                if (answer === "third_answer") {
                    dispatch(addGameMove({user: user, bot: 1}))
                } else if (answer === "fourth_answer") {
                    dispatch(addGameMove({user: user, bot: 2}))
                } else {
                    dispatch({
                        type: PAGE_ADD_GAME_LOGIC + SUCCESS,
                        payload: {bot: response.data.message, user: user}
                    })
                }
            }
        )
        .catch(error => dispatch({
            type: PAGE_ADD_GAME_LOGIC + FAIL,
            payload: error.response.data
        }))
};

const gameAnswerRequest = (schema) => (dispatch) => {
    dispatch({type: PAGE_ADD_GAME_ANSWER + START})
    return axios.post(ADD_ANSWER_GAME, schema)
        .then(response => dispatch({
            type: PAGE_ADD_GAME_ANSWER + SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: PAGE_ADD_GAME_ANSWER + FAIL,
            payload: error.response.data
        }));
}

const checkResponse = (response_id) => {
    return axios.post(CHECK_RESPONSE, {response_id});
};

const finalScreenRequest = (schema) => (dispatch) => {
    dispatch({type: GAME_FINAL_SCREEN + START})
    return axios.post(GAME_FINAL_SCREEN, schema)
        .then(response => dispatch({
            type: PAGE_GET_FINAL_SCREEN + SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: PAGE_GET_FINAL_SCREEN + FAIL,
            payload: error.response.data
        }));
}

const codeRequest = (schema) => (dispatch) => {
    dispatch({type: PAGE_SEND_CODE + START})
    return axios.post(SEND_CODE, schema)
        .then(response => dispatch({
            type: PAGE_SEND_CODE + SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: PAGE_SEND_CODE + FAIL,
            payload: error.response.data
        }));
}

const addCodeRequest = (schema) => (dispatch) => {
    dispatch({type: PAGE_ADD_CODE + START})
    return axios.post(ADD_CODE, schema)
        .then(response => dispatch({
            type: PAGE_ADD_CODE + SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: PAGE_ADD_CODE + FAIL,
            payload: error.response.data
        }));
}

export {
    loginRequest,
    userAnswerRequest,
    gameLogicRequest,
    gameAnswerRequest,
    finalScreenRequest,
    addCodeRequest,
    codeRequest,
    checkResponse,
}
