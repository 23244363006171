import {useSelector} from "react-redux";
import {pageCounterSelector, pageCurrentSelector} from "@/selectors";
import "@assets/styles/index.scss";
import map from "@/pagesMap";
import {useRef, memo, useState, useContext} from "react";
import {useTransition} from "react-spring";
import {Game} from "@/pages";
import "./App.scss";
import {backgroundMp3, backgroundWav} from "@assets/audios";
import {Button, Preloader} from "@/components";
import {useTranslation} from "react-i18next";
import {AppContext} from "@/App";

const App = () => {
    const {t} = useTranslation();
    const pageCounter = useSelector(pageCounterSelector);
    const currentPage = useSelector(pageCurrentSelector);
    const prevIndexRef = useRef(currentPage);
    const backgroundAudioRef = useRef();
    const [isShow, setIsShow] = useState(true);
    const [audioRef, setAudioRef] = useState(null);
    const audioRefCB = (audio) => setAudioRef(audio);
    const {playedStatus, isLoading} = useContext(AppContext);

    const transitions = useTransition(map[pageCounter].page, {
        initial: false,
        ...map[pageCounter].animation(pageCounter, prevIndexRef, currentPage),
        enter: {transform: `translate(0%, 0%)`},
        config: {duration: 200},
        onRest: () => {
            prevIndexRef.current = currentPage;
        }
    });

    const handleClick = () => {
        setIsShow(false);

        backgroundAudioRef.current.volume = 0.02;
        const playPromise = backgroundAudioRef?.current?.play();
        const playPromiseIntro = audioRef?.current?.play();

        if (playPromise !== undefined) {
            playPromise.then(() => {
                backgroundAudioRef?.current?.play();
            }).catch(error => {
                backgroundAudioRef?.current?.pause()
            });
        }

        if (playPromiseIntro !== undefined) {
            playPromiseIntro.then(() => {
                audioRef?.current?.pause();
            }).catch(error => {
                audioRef?.current?.pause()
            });
        }
    };

    return (
        <>
            {
                isLoading ? <Preloader/> : <>
                    {
                        isShow ? <div className="App__agreement">
                            <div className="App__agreement-block">
                                {
                                    playedStatus ? <>
                                        <p className="App__agreement-text">{t("You have only one attempt to complete the game, but there is always a second chance in life.")}</p>
                                    </> : <>
                                        <p className="App__agreement-text">{t("Don't forget to turn on the sound.")}</p>
                                        <p className="App__agreement-text">
                                            {t("By clicking the 'Next' button, you consent to the processing of your personal data.")}
                                        </p>
                                        <Button handleClick={handleClick}
                                                className="btn btn-link btn-small">{t("Next")}</Button>
                                    </>
                                }
                            </div>
                        </div> : null
                    }
                    <audio ref={backgroundAudioRef} autoPlay loop>
                        <source src={backgroundMp3} type="audio/mp3"/>
                        <source src={backgroundWav} type="audio/wav"/>
                    </audio>
                    {transitions((style, item) => {
                            const Page = item;

                            return (
                                <Game style={style}>
                                    <Page audioRefCB={audioRefCB} audioRef={audioRef}/>
                                </Game>
                            )
                        }
                    )}
                </>
            }
        </>
    );
}

export default memo(App);
