const ROOT_PATH = "https://enter.abp.ua";
const ROOT_USER_PATH = "/v1/user";
const ROOT_API_PATH = "/api"

const USER_PATH = "/add-user";
const ANSWER_PATH = "/add-answer";
const GAME_ANSWER_PATH = "/add-answer-game";
const GAME_LOGIC_PATH = "/game-logic";
const GET_FINAL_SCREEN = "/get-final-screen";
const SEND_CODE_PATH = "/send-code";
const ADD_CODE_PATH = "/add-sms-code";
const CHECK_RESPONSE_PATH = "/check-response";

const ADD_USER = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + USER_PATH;
const ADD_ANSWER = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + ANSWER_PATH;
const ADD_ANSWER_GAME = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + GAME_ANSWER_PATH;
const GAME_LOGIC = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + GAME_LOGIC_PATH;
const GAME_FINAL_SCREEN = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + GET_FINAL_SCREEN;
const SEND_CODE = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + SEND_CODE_PATH;
const ADD_CODE = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + ADD_CODE_PATH;
const CHECK_RESPONSE = ROOT_PATH + ROOT_API_PATH + ROOT_USER_PATH + CHECK_RESPONSE_PATH;

export {
    ADD_USER,
    ADD_ANSWER,
    ADD_ANSWER_GAME,
    GAME_LOGIC,
    GAME_FINAL_SCREEN,
    SEND_CODE,
    ADD_CODE,
    CHECK_RESPONSE,
}
