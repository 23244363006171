import {InfoList, InfoTime, Preloader,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {memo, useContext} from "react";
import MathScreen from "@modularComponents/MathScreens/MathScreen";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {useSelector} from "react-redux";
import {apiLoadingSelector} from "@/selectors";
import {AppContext} from "@/App";

const GameProfit = () => {
    const {lang} = useContext(AppContext);
    const isLoading = useSelector(apiLoadingSelector);

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioProfitWav, mp3: audios[lang].audioProfitMp3}}
                               subtitle={subtitles[lang].profit} autoplay={true}/>
                        <MathScreen/>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--building">
                <InfoList>
                    <InfoTime/>
                </InfoList>
            </RightSide>
        </div>
    );
};

export default memo(GameProfit);
