import {Title, Button} from "@/components/index";
import "./Calls.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const Calls = ({setCallingTitle, setIsCalling, refs}) => {
    const {t} = useTranslation();

    const handleClick = (title, audioId) => {
        setIsCalling(true);
        setCallingTitle(title);
        refs[audioId].current.play();
    };

    return (
        <div className="calls">
            <Title className="calls__title">{t("Make a call:")}</Title>

            <div className="calls__btn-group">
                <Button handleClick={() => handleClick("Call SEO ...", 0)} className="btn btn-link btn-call">
                    {t("SEO")}
                    <span className="btn-call-control__phone">+380555445544</span>
                </Button>
                <Button handleClick={() => handleClick("Call Team ...", 1)} className="btn btn-link btn-call">
                    {t("Team")}
                    <span className="btn-call-control__phone">+380333445533</span>
                </Button>
                <Button handleClick={() => handleClick("Call Partner ...", 2)} className="btn btn-link btn-call">
                    {t("Partner")}
                    <span className="btn-call-control__phone">+380555445511</span>
                </Button>
            </div>
        </div>
    );
};

Calls.propTypes = {
    setCallingTitle: PropTypes.func.isRequired,
    setIsCalling: PropTypes.func.isRequired,
    refs: PropTypes.array.isRequired
};

export default Calls;
