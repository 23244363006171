import {useMemo} from "react";
import PropTypes from "prop-types";
import "./FormControl.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {Button} from "@/components";
import {codeRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {phoneSelector, userIDSelector} from "@/selectors";

const FormControl = ({info, isCode, label, children}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const id = useSelector(userIDSelector);
    const phone = useSelector(phoneSelector);

    const classes = useMemo(() => (
        classNames(
            "form-control",
            {
                ["form-control--error"]: info.error
            })
    ), [info.error]);

    const resendCodeClick = () => {
        dispatch(codeRequest({id, phone}));
    };

    return (
        <div className={classes}>
            {children}
            <span className="form-control__helper">{t(info.helper)}</span>
            <label
                className={
                    info.text || info.showMask
                        ? "form-control__label form-control__label--full"
                        : "form-control__label"
                }
            >
                {label}
            </label>
            {
                isCode ? <>
                    <span
                        className="form-control__helper form-control__helper--small">{t("Code was sent to the phone number", {phone})}</span>
                    <Button handleClick={resendCodeClick} className="btn btn-link btn-small">{t("Resend code")}</Button>
                </> : null
            }
        </div>
    );
};

FormControl.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    info: PropTypes.object.isRequired,
    isCode: PropTypes.bool
};

export default FormControl;
