import PropTypes from "prop-types";
import "./Button.scss";

const Button = ({children, className, handleClick, disabled, innerRef}) => {
    return (
        <button ref={innerRef} type="button" onClick={handleClick} className={className} disabled={disabled}>
            {children}
        </button>
    );
};

Button.propTypes = {
    innerRef: PropTypes.object,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default Button;
