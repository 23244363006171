import {Button, FormControl, Preloader, Title,} from "@components/index";
import {Audio} from "@modularComponents/index";
import {LeftSide, RightSide} from "@layout/index";
import '@/i18n';
import {useTranslation} from "react-i18next";
import "./Game.scss";
import {useDispatch, useSelector} from "react-redux";
import {addName, addPhone, moveBack} from "@/actionCreators";
import icons from "@assets/icons";
import {memo, useContext, useEffect, useRef, useState} from "react";
import InputMask from "react-input-mask";
import {addCodeRequest, loginRequest} from "@/api/request";
import {
    apiLoadingSelector,
    errorCodeSelector,
    errorMsgSelector,
    nameSelector,
    phoneSelector,
    userIDSelector
} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameLogin = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const phone = useSelector(phoneSelector);
    const errorMsg = useSelector(errorMsgSelector);
    const name = useSelector(nameSelector);
    const id = useSelector(userIDSelector);
    const errorCode = useSelector(errorCodeSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const btnRef = useRef(null);
    const {responseId} = useContext(AppContext);
    const minNumberPhone = 5;
    const [nameInput, setNameInput] = useState({text: "", helper: "", error: false});
    const [phoneInput, setPhoneInput] = useState({
        text: "",
        helper: "",
        error: false,
        showMask: true
    });

    const disabledBtn = !(!!nameInput.text && phoneInput.text.length);

    useEffect(() => {
        nameRef.current.focus();
    }, []);

    useEffect(() => {
        if (errorCode === 2) {
            dispatch(loginRequest({
                id,
                name,
                phone
            }, 2));
        }
    }, [errorCode]);

    const nameClickRef = (e) => {
        if (e.target.value) {
            if (e.keyCode === 13 && !nameInput.error) {
                nameRef.current.blur();
                phoneRef.current.focus();
            }
        } else {
            setNameInput({...nameInput, helper: "The field must not be empty", error: true});
        }
    };

    const phoneClickRef = (e) => {
        if (e.target.value.length < minNumberPhone) {
            setPhoneInput({...phoneInput, helper: "Incorrect number format", error: true});
        } else {
            if (e.keyCode === 13 && !phoneInput.error) {
                phoneRef.current.blur();
                btnRef.current.click();
            }
        }
    };

    const handleNameChange = (e) => {
        const value = e.target.value;

        dispatch(addName(value));

        value.length ?
            setNameInput({text: value, helper: "", error: false}) :
            setNameInput({text: value, helper: "The field must not be empty", error: true});
    };



    const handlePhoneChange = (e) => {
        const value = e.target.value;

        dispatch(addPhone(value.replace(/\s+/g, '')));

        value.length < minNumberPhone ?
            setPhoneInput({...phoneInput, text: value, helper: "Incorrect number format", error: true}) :
            setPhoneInput({
                ...phoneInput,
                text: value,
                helper: "",
                error: false
            })
    };

    const handleClick = () => {
        dispatch(addCodeRequest({
            phone,
            name: nameInput.text,
            response_id: responseId,
        }));
    };

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Button handleClick={() => dispatch(moveBack())} className={"btn btn-close"}>
                            <icons.Close/>
                            <span>{t("Close")}</span>
                        </Button>
                        <Audio audio={{wav: audios[lang].audioLoginWav, mp3: audios[lang].audioLoginMp3}}
                               subtitle={subtitles[lang].login}
                               autoplay={true}/>
                        <Title>{t("Please, introduce yourself")}</Title>
                        <FormControl info={nameInput} label={t("Name")}>
                            <input className="form-control__input"
                                   onChange={handleNameChange}
                                   onKeyUp={nameClickRef}
                                   value={nameInput.text}
                                   ref={nameRef}
                                   type="text"/>
                        </FormControl>
                        <FormControl info={phoneInput} label={t("Phone Number")}>
                            <input className="form-control__input form-control__input--number"
                                   onChange={handlePhoneChange}
                                   onKeyUp={phoneClickRef}
                                   value={phoneInput.text}
                                   ref={nameRef}
                                   maxLength={20}
                                   type="text" pattern="\d*"/>
                        </ FormControl>
                        <div className="left-side__error">{
                            errorMsg ? t("The phone must be unique") : ""
                        }</div>
                        <Button innerRef={btnRef} handleClick={handleClick} className="btn btn-primary"
                                disabled={disabledBtn}>{t("Next")}</Button>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--blur"/>
        </div>
    );
};

export default memo(GameLogin);
