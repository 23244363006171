import { ReactComponent as Clock } from "@assets/images/icons/clock.svg";
import { ReactComponent as Suitcase } from "@assets/images/icons/suitcase.svg";
import { ReactComponent as Polygon } from "@assets/images/icons/polygon.svg";
import { ReactComponent as Rectangle } from "@assets/images/icons/rectangle.svg";
import { ReactComponent as Ellipse } from "@assets/images/icons/ellipse.svg";
import { ReactComponent as Mouse } from "@assets/images/icons/mouse.svg";
import { ReactComponent as Pause } from "@assets/images/icons/pause.svg";
import { ReactComponent as Play } from "@assets/images/icons/play.svg";
import { ReactComponent as Close } from "@assets/images/icons/close.svg";
import { ReactComponent as User } from "@assets/images/icons/user.svg";
import { ReactComponent as Hold } from "@assets/images/icons/hold.svg";
import { ReactComponent as Mute } from "@assets/images/icons/mute.svg";
import { ReactComponent as Note } from "@assets/images/icons/note.svg";
import { ReactComponent as Volume } from "@assets/images/icons/volume.svg";
import { ReactComponent as More } from "@assets/images/icons/more.svg";
import { ReactComponent as Phone } from "@assets/images/icons/phone.svg";
import { ReactComponent as Telegram } from "@assets/images/icons/telegram.svg";
import { ReactComponent as Facebook } from "@assets/images/icons/fb.svg";
import { ReactComponent as PreloaderOuter } from "@assets/images/icons/preloader-outer.svg";
import { ReactComponent as PreloaderInnerCircle } from "@assets/images/icons/preloader-inner-circle.svg";
import { ReactComponent as PreloaderInnerHalf } from "@assets/images/icons/preloader-inner-half.svg";

const icons = {
   Clock,
   Suitcase,
   Polygon,
   Rectangle,
   Ellipse,
   Mouse,
   Pause,
   Play,
   User,
   More,
   Volume,
   Note,
   Mute,
   Hold,
   Phone,
   Close,
   Facebook,
   Telegram,
   PreloaderOuter,
   PreloaderInnerCircle,
   PreloaderInnerHalf,
};

export default icons;
