import {Button, FormControl, InfoList, InfoTime, Preloader, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import icons from "@assets/icons";
import {goloOtherImage, goloOtherImageWebp, goloTableImage, goloTableImageWebp} from "@assets/images";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {showCurrentPage} from "@/actionCreators";
import {useDispatch, useSelector} from "react-redux";
import {
    apiLoadingSelector,
    arrTextSelector,
    audioSelector,
    subtitleSelector,
    titleSelector,
    userIDSelector
} from "@/selectors";
import {memo, useEffect, useRef, useState} from "react";
import {userAnswerRequest} from "@/api/request";
import {Audio} from "@/modularComponents";

const GameOtherChoice = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const title = useSelector(titleSelector);
    const audio = useSelector(audioSelector);
    const subtitle = useSelector(subtitleSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const [requestValue, requestText] = useSelector(arrTextSelector);

    const [otherInput, setOtherInput] = useState({text: "", error: false, helper: ""});
    const inputRef = useRef(null);
    const btnRef = useRef(null);
    const disabledBtn = !otherInput.text;

    useEffect(() => {
        inputRef.current.focus()
    }, []);

    const inputClickRef = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault()

            if (!otherInput.text) {
                setOtherInput({...otherInput, helper: "The field must not be empty", error: true});
            } else {
                inputRef.current.blur();
                btnRef.current.click();
            }
        }
    };

    const handleCodeChange = (e) => {
        const value = e.target.value;

        value.length ?
            setOtherInput({...otherInput, text: value, helper: "", error: false}) :
            setOtherInput({...otherInput, text: value, helper: "The field must not be empty", error: true})
    };

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Button handleClick={() => dispatch(showCurrentPage())} className={"btn btn-close"}>
                            <icons.Close/>
                            <span>{t("Close")}</span>
                        </Button>
                        <Audio audio={{...audio}} subtitle={subtitle} autoplay={true}/>
                        <Title>{title}</Title>
                        <FormControl
                            label={t("Your Own Option")}
                            info={otherInput}
                        >
                    <textarea
                        className="form-control__input form-control__textarea"
                        value={otherInput.text}
                        onChange={handleCodeChange}
                        onKeyDown={inputClickRef}
                        rows="14"
                        maxLength="750"
                        ref={inputRef}
                    />
                        </FormControl>
                        <Button handleClick={() => dispatch(userAnswerRequest({
                            id: userID,
                            [requestValue]: 0,
                            [requestText]: otherInput.text
                        }))}
                                className="btn btn-primary"
                                disabled={disabledBtn}
                                innerRef={btnRef}
                        >
                            {t("Send")}
                        </Button>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--blur">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                <div className="right-side__golo">
                    <div className="right-side__golo-anim">
                        <picture>
                            <source srcSet={goloOtherImageWebp} type="image/webp"/>
                            <source srcSet={goloOtherImage} type="image/png"/>
                            <img className="right-side__golo-img" src={goloOtherImage} alt=""/>
                        </picture>
                    </div>
                    <picture>
                        <source srcSet={goloTableImageWebp} type="image/webp"/>
                        <source srcSet={goloTableImage} type="image/png"/>
                        <img className="right-side__golo-table" src={goloTableImage} alt=""/>
                    </picture>
                </div>
            </RightSide>
        </div>
    );
};

export default memo(GameOtherChoice);
