import {Button, InfoList, InfoTime, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {showCurrentPage} from "@/actionCreators";
import {useDispatch, useSelector} from "react-redux";
import {audioSelector, titleSelector} from "@/selectors";
import {memo, useContext, useEffect, useRef} from "react";
import {Audio} from "@/modularComponents";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameNoInvestors = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const title = useSelector(titleSelector);
    const audio = useSelector(audioSelector);
    const btnRef = useRef(null);

    useEffect(() => {
        btnRef.current.focus()
    }, []);

    return (
        <div className="game__element">
            <LeftSide>
                <Audio audio={{...audio}} subtitle={subtitles[lang].noInvestorSteveJobs} autoplay={true}/>
                <Title>{title}</Title>
                <Button handleClick={() => dispatch(showCurrentPage())}
                        className="btn btn-primary"
                        innerRef={btnRef}
                >
                    {t("Return")}
                </Button>
            </LeftSide>
            <RightSide className="right-side--investors">
                <InfoList>
                    <InfoTime/>
                </InfoList>
            </RightSide>
        </div>
    );
};

export default memo(GameNoInvestors);
