const prettifyNum = (num) => {
    const string = num.toString();
    return string.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
}

const timerRun = (time, result) => {
    let {ms: updatedMs, s: updatedS, m: updatedM, h: updatedH} = time;

    if (updatedM === 60) {
        updatedH++;
        updatedM = 0;
    }

    if (updatedS === 60) {
        updatedM++;
        updatedS = 0;
    }

    if (updatedMs === 100) {
        updatedS++;
        updatedMs = 0;
    }

    updatedMs++;

    return result(updatedMs, updatedS, updatedM, updatedH);
};

const getTime = time => {
    const seconds = time.s < 10 ? "0" + time.s : time.s;
    const minutes = time.m < 10 ? "0" + time.m : time.m;

    return minutes + ":" + seconds;
};

const deleteUnderscore = (text) => {
    const regExp = text.match(/\d{1,3}/g);

    if (regExp) {
        return +regExp.join('');
    } else {
        return "";
    }
};

const scrollTo = (element, duration) => {
    if (!element) return;

    let target = element.scrollHeight;

    if (duration < 0) return false;

    if (duration === 0) {
        element.scrollTop = target;
        return true;
    }

    const startTime = Date.now();
    const endTime = startTime + duration;

    const startTop = Math.ceil(element.scrollTop);
    const distance = target - startTop;

    const smoothStep = (start, end, point) => {
        if (point <= start) return 0;
        if (point >= end) return 1;

        const x = (point - start) / (end - start);
        return x * x * (3 - 2 * x);
    };

    let previousTop = Math.ceil(element.scrollTop);

    const scrollFrame = () => {
        if (Math.ceil(element.scrollTop) !== previousTop) return false;

        const now = Date.now();
        const point = smoothStep(startTime, endTime, now);
        const frameTop = startTop + (distance * point);
        element.scrollTop = Math.ceil(frameTop);

        if (now >= endTime) {
            return true
        }

        if (element.offsetHeight + Math.ceil(element.scrollTop) >= element.scrollHeight) {
            return true
        }

        previousTop = Math.ceil(element.scrollTop);

       setTimeout(scrollFrame, 0);
    };

    setTimeout(scrollFrame, 0);
};

const audioSyncWithText = function (audio, subtitleContainer, subtitle, isPlaying, setStartTimer = null) {
    var checker = null;

    audio.addEventListener("timeupdate", function (e) {
        var el;
        subtitle.forEach(function (element, index, array) {
            if ((audio.currentTime * 1000) >= element.start && (audio.currentTime * 1000) <= element.end) {
                if (checker !== index) {
                    checker = index

                    el = document.createElement('span');
                    el.setAttribute("id", "c_" + index);
                    el.innerText = subtitle[index].part + " ";
                    subtitleContainer.appendChild(el);
                }

                // console.log(audioPlayer.currentTime * 1000, element.start)
                // subtitles.scrollTo(32,subtitles.children[index].scrollHeight)
                // while(subtitles.hasChildNodes())
                //     subtitles.removeChild(subtitles.firstChild)
            }

            if (audio.duration === audio.currentTime) {
                isPlaying(false);

                if (setStartTimer) {
                    setStartTimer(true);
                }
            }
        });
    });
};

const getRandomInt = (max) => Math.floor(Math.random() * max);

export {prettifyNum, timerRun, getRandomInt, getTime, deleteUnderscore, audioSyncWithText, scrollTo};