import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import icons from "@assets/icons";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {memo, useContext, useEffect} from "react";
import {finalScreenRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, finalScreenSelector, userIDSelector} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {Preloader} from "@/components";
import {AppContext} from "@/App";

const GameFinal = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const final = useSelector(finalScreenSelector);
    const id = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);

    useEffect(() => {
        dispatch(finalScreenRequest({
            id,
            lang,
        }));
    }, []);

    return (
        <div className="game__element">
            <LeftSide>
                {
                    !isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioFinalWav, mp3: audios[lang].audioFinalMp3}}
                               subtitle={subtitles[lang].final}
                               autoplay={true}/>
                        <p className="left-side__info left-side__info--final">{final?.title ? final.title : ""}</p>
                        <p className="left-side__info left-side__info--final">{final?.text ? final.text : ""}</p>
                        <div className="left-side__end">
                            The end
                        </div>

                    </>
                }
                <div className="left-side__socials">
                    <a href="https://t.me/abpcorp_bot" target="_blank"
                       className="btn btn-link btn-link--socials">
                        <icons.Telegram/>
                        <span>{t("@Telegram")}</span>
                    </a>
                    <div className="fb-share-button" style={{display: 'flex', alignItems: 'center'}}
                         data-href="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fabp.rexit.info&layout=button&size=large&width=119&height=28&appId%22"
                         data-layout="button"
                         data-size="large">
                        <a target="_blank"
                           href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=https%3A%2F%2Fabp.rexit.info%2F&display=popup&ref=plugin&src=share_button"
                           className="fb-xfbml-parse-ignore btn btn-link btn-link--socials">
                            <icons.Facebook/>
                            <span>{t("Share the game on facebook")}</span>
                        </a>
                    </div>
                </div>
            </LeftSide>
            <RightSide className="right-side--final"/>
        </div>
    );
};

export default memo(GameFinal);
