import {Button} from "@/components";
import icons from "@assets/icons";
import "./Calls.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const CallsControl = ({intrv, setControl, refs}) => {
    const {t} = useTranslation();

    const handleClick = () => {
        clearTimeout(intrv);
        setControl(false)

        refs.map((item) => {
            item.current.pause();
        });
    };

    return (
        <div className="calls__control">
            <div className="calls__btn-control">
                <Button className="btn btn-call-control">
                  <span>
                     <icons.Mute/>
                  </span>
                    <span className="btn-call-control__text">{t("Mute")}</span>
                </Button>

                <Button className="btn btn-call-control">
                  <span>
                     <icons.Hold/>
                  </span>
                    <span className="btn-call-control__text">{t("Hold")}</span>
                </Button>

                <Button className="btn btn-call-control">
                  <span>
                     <icons.Note/>
                  </span>
                    <span className="btn-call-control__text">{t("Note")}</span>
                </Button>
            </div>

            <div className="calls__btn-control">
                <Button className="btn btn-call-control">
                  <span>
                     <icons.Volume/>
                  </span>
                </Button>

                <Button handleClick={handleClick} className="btn btn-call-control btn-call-control--phone">
                  <span>
                     <icons.Phone/>
                  </span>
                </Button>

                <Button className="btn btn-call-control">
                  <span>
                     <icons.More/>
                  </span>
                </Button>
            </div>
        </div>
    );
};

CallsControl.propTypes = {
    setControl: PropTypes.func,
    intrv: PropTypes.number,
    refs: PropTypes.array
};

export default CallsControl;