import {memo, useContext, useMemo} from "react";
import {
    Title,
    QuizList,
    QuizItem,
    InfoList,
    InfoTime, Preloader,
} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {useSpring, animated, config} from "react-spring";
import {
    dnaBGParallaxImage,
    dnaBGParallaxImageWebp,
    dnaParallaxImage, dnaParallaxImageWebp,
    goloFireballImage,
    goloFireballImageWebp
} from "@assets/images";
import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameRole = () => {
    const {lang} = useContext(AppContext);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const quizText = useMemo(() => [t("Co-founder"), t("Team Leader"), t("Founder, CEO")], []);

    const [props, set] = useSpring(() => ({xy: [0, 0], config: config.molasses}));

    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
    const trans1 = (x, y) => `translate3d(${x / -60}px,${y / -60}px,0)`;
    const trans2 = (x, y) => `translate3d(${x / 35}px,${y / 35}px,0)`;

    return (
        <div className="game__element" onMouseMove={({clientX: x, clientY: y}) => set({xy: calc(x, y)})}>
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioRoleWav, mp3: audios[lang].audioRoleMp3}} subtitle={subtitles[lang].role}
                               autoplay={true}/>
                        <Title>{t("Your role in your dream project:")}</Title>
                        <QuizList>
                            {quizText.map((text, i) => (
                                <QuizItem handleClick={() => dispatch(userAnswerRequest({id: userID, role: i + 1}))}
                                          key={i}
                                          text={text}/>
                            ))}
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--dna">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                <div className="prx-container">
                    <animated.div className="prx-dna-bg" style={{transform: props.xy.to(trans1)}}>
                        <picture>
                            <source srcSet={dnaBGParallaxImageWebp} type="image/webp"/>
                            <source srcSet={dnaBGParallaxImage} type="image/png"/>
                            <img src={dnaBGParallaxImage} alt=""/>
                        </picture>
                    </animated.div>
                    <animated.div className="prx-dna" style={{transform: props.xy.to(trans2)}}>
                        <picture>
                            <source srcSet={dnaParallaxImageWebp} type="image/webp"/>
                            <source srcSet={dnaParallaxImage} type="image/png"/>
                            <img src={dnaParallaxImage} alt=""/>
                        </picture>
                    </animated.div>
                </div>
            </RightSide>
        </div>
    );
};

export default memo(GameRole);
