import {Button, InfoList, InfoTime, Preloader} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio, MiniGame, MiniGameTable} from "@modularComponents/index"
import icons from "@assets/icons";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {memo, useContext, useEffect, useState} from "react";
import {gameAnswerLoadingSelector, gameMoveSelector, gameStatusSelector, userIDSelector} from "@/selectors";
import {gameAnswerRequest} from "@/api/request";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameChallenge = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const gameStatus = useSelector(gameStatusSelector);
    const isLoading = useSelector(gameAnswerLoadingSelector);
    const userID = useSelector(userIDSelector);
    const [gameMove, setGameMove] = useState([]);
    const gameMoves = useSelector(gameMoveSelector);

    const calcMovePoint = arr => {
        const result = arr.reduce((acc, el) => {
            acc[el] = (acc[el] || 0) + 1;
            return acc;
        }, {});

        if (result[2] <= 6 && result[2] >= 2) {
            return 2
        }

        if (result[2] === 7) {
            return 0
        }

        if (result[1] === 7) {
            return 10
        }

        if (arr[0] === 2 && result[1] === 6) {
            return 8
        }

        if ((arr[1] === 2 || arr[2] === 2 || arr[3] === 2 || arr[5] === 2) && result[1] === 6) {
            return 6
        }

        if (arr[4] === 2 && result[1] === 6) {
            return 7
        }

        if (arr[6] === 2 && result[1] === 6) {
            return 4
        }

        return -1;
    }

    useEffect(() => {
        if (gameMoves.user !== 0) {
            setGameMove([...gameMove, gameMoves.user]);
        }
    }, [gameMoves]);


    const handleClick = () => {
        dispatch(gameAnswerRequest({
            user_id: userID,
            first_answer: gameMove[0],
            second_answer: gameMove[1],
            third_answer: gameMove[2],
            fourth_answer: gameMove[3],
            fifth_answer: gameMove[4],
            sixth_answer: gameMove[5],
            seventh_answer: gameMove[6],
            result: calcMovePoint(gameMove)
        }));
    };

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        {
                            !gameStatus ? <>
                                <Audio
                                    audio={{wav: audios[lang].audioChallengeWav, mp3: audios[lang].audioChallengeMp3}}
                                    subtitle={subtitles[lang].challenge} autoplay={true}/>
                                <MiniGameTable>
                                    <div className="mini-game-table__row">
                                        <div className="mini-game-table__header">
                                            <div className="mini-game-table__title">{t("Combination of moves")}</div>
                                        </div>

                                        <div className="mini-game-table__content">
                                            <div className="mini-game-table__player">
                                                <div className="mini-game-table__text">{t("You")}</div>

                                                <div className="mini-game-table__player-turns">

                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Polygon/>
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Rectangle/>
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Rectangle/>
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Polygon/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mini-game-table__player">
                                                <div className="mini-game-table__text">{t("Second player")}</div>

                                                <div className="mini-game-table__player-turns">
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Polygon/>
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Rectangle/>
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Polygon/>
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        <icons.Rectangle/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mini-game-table__row">
                                        <div className="mini-game-table__header">
                                            <div className="mini-game-table__title">{t("Result")}</div>
                                        </div>

                                        <div className="mini-game-table__content">
                                            <div className="mini-game-table__player">
                                                <div className="mini-game-table__text">{t("You")}</div>

                                                <div className="mini-game-table__player-turns">
                                                    <div className="mini-game-table__player-turn">
                                                        &#43; 3
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        &#8722; 3
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        &#43; 5
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        &#8722; 5
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mini-game-table__player">
                                                <div className="mini-game-table__text">{t("Second player")}</div>

                                                <div className="mini-game-table__player-turns">
                                                    <div className="mini-game-table__player-turn">
                                                        &#43; 3
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        &#8722; 3
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        &#8722; 5
                                                    </div>
                                                    <div className="mini-game-table__player-turn">
                                                        &#43; 5
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MiniGameTable>
                            </> : null
                        }
                        {
                            gameStatus ? <>
                                <Audio audio={{wav: audios[lang].audioChallengeEndWav, mp3: audios[lang].audioChallengeEndMp3}}
                                       subtitle={subtitles[lang].challengeEnd} autoplay={true}/>
                                <Button
                                    handleClick={handleClick}
                                    className="btn btn-primary"
                                >
                                    {t("Continue")}
                                </Button>
                            </> : null
                        }
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--gray-blue">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                <MiniGame/>
            </RightSide>
        </div>
    );
}

export default memo(GameChallenge);
