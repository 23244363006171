import {
    Intro,
    GameLogin,
    GameCode,
    GameOtherChoice,
    GameRole,
    GameSpheres,
    GameGrant,
    GameMotivations,
    GameInvestors,
    GameChallenge,
    GameResources,
    GameCalls,
    GameMakeMove,
    GameProfit,
    GameFinal,
} from "@pages/index";
import GameNoInvestors from "@pages/Game/GameNoInvestors";

const pages = {
    "intro": Intro,
    "login": GameLogin,
    "code": GameCode,
    "role": GameRole,
    "spheres": GameSpheres,
    "otherChoice": GameOtherChoice,
    "grant": GameGrant,
    "motivations": GameMotivations,
    "investors": GameInvestors,
    "challenge": GameChallenge,
    "resources": GameResources,
    "calls": GameCalls,
    "makeMove": GameMakeMove,
    "profit": GameProfit,
    "final": GameFinal,
    "noInvestors": GameNoInvestors,
};

const map = [
    {
        "page": pages.intro,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.login,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.code,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.role,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.spheres,
        "ways": 14,
        "animation": (nextPage, prevPage, currentPage) => ({
            from: {
                transform: prevPage.current === currentPage ?
                    nextPage > prevPage.current
                        ? `translate(0%, 100%)`
                        : `translate(0%, -100%)` :
                    nextPage > prevPage.current
                        ? `translate(100%, 0%)`
                        : `translate(-100%, 0%)`
            },
            leave: {
                transform: prevPage.current === currentPage ?
                    nextPage > prevPage.current
                        ? `translate(0%, -100%)`
                        : `translate(0%, 100%)` :
                    nextPage > prevPage.current
                        ? `translate(-100%, 0%)`
                        : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.grant,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.motivations,
        "ways": 14,
        "animation": (nextPage, prevPage, currentPage) => ({
            from: {
                transform: prevPage.current === currentPage ?
                    nextPage > prevPage.current
                        ? `translate(0%, 100%)`
                        : `translate(0%, -100%)` :
                    nextPage > prevPage.current
                        ? `translate(100%, 0%)`
                        : `translate(-100%, 0%)`
            },
            leave: {
                transform: prevPage.current === currentPage ?
                    nextPage > prevPage.current
                        ? `translate(0%, -100%)`
                        : `translate(0%, 100%)` :
                    nextPage > prevPage.current
                        ? `translate(-100%, 0%)`
                        : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.investors,
        "ways": 14,
        "nextWay": 15,
        "animation": (nextPage, prevPage, currentPage) => ({
            from: {
                transform: prevPage.current === currentPage ?
                    nextPage > prevPage.current
                        ? `translate(0%, 100%)`
                        : `translate(0%, -100%)` :
                    nextPage > prevPage.current
                        ? `translate(100%, 0%)`
                        : `translate(-100%, 0%)`
            },
            leave: {
                transform: prevPage.current === currentPage ?
                    nextPage > prevPage.current
                        ? `translate(0%, -100%)`
                        : `translate(0%, 100%)` :
                    nextPage > prevPage.current
                        ? `translate(-100%, 0%)`
                        : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.challenge,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.resources,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.calls,
        "ways": 15,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.makeMove,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.profit,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.final,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(100%, 0%)`
                    : `translate(-100%, 0%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(-100%, 0%)`
                    : `translate(100%, 0%)`
            }
        })
    },
    {
        "page": pages.otherChoice,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(0%, 100%)`
                    : `translate(0%, -100%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(0%, -100%)`
                    : `translate(0%, 100%)`
            }
        })
    },
    {
        "page": pages.noInvestors,
        "ways": null,
        "animation": (nextPage, prevPage) => ({
            from: {
                transform: nextPage > prevPage.current
                    ? `translate(0%, 100%)`
                    : `translate(0%, -100%)`
            },
            leave: {
                transform: nextPage > prevPage.current
                    ? `translate(0%, -100%)`
                    : `translate(0%, 100%)`
            }
        })
    },
];

export default map;
