import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {timerDownSelector, userIDSelector} from "@/selectors";
import {addTimerDown} from "@/actionCreators";
import PropTypes from "prop-types";

const {useEffect} = require("react");

const CallsTimer = ({startTimer}) => {
    const dispatch = useDispatch();
    const timerDown = useSelector(timerDownSelector);
    const userID = useSelector(userIDSelector);
    const {s: seconds, m: minutes} = timerDown;

    useEffect(() => {
        let timer;
        if (startTimer) {
            timer = setTimeout(() => dispatch(addTimerDown(timerDown, () => {
                clearTimeout(timer);
                dispatch(userAnswerRequest({id: userID, solution: 4}))
            })), 1000);
        }
        return () => clearTimeout(timer);
    });

    return <span>{minutes}&#58;{seconds < 10 ? `0${seconds}` : seconds}</span>;
}

CallsTimer.propTypes = {
    startTimer: PropTypes.bool,
};

export default CallsTimer;