import {memo, useContext, useMemo} from "react";
import {InfoList, InfoTime, Preloader, QuizItem, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import {goloFireballImage, goloFireballImageWebp, goloTableRedImage, goloTableRedImageWebp} from "@assets/images";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameResources = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const quizText = useMemo(() => [
        t("Start negotiating large investments that will most likely take away your majority share"),
        t("Stay within your country and develop on your own"),
    ], []);

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioResourcesWav, mp3: audios[lang].audioResourcesMp3}}
                               subtitle={subtitles[lang].resources} autoplay={true}/>
                        <Title>{t("Expansion requires multiples of the resources you have, so you are faced with a choice:")}</Title>
                        <QuizList>
                            <QuizList>
                                {quizText.map((text, i) => (
                                    <QuizItem
                                        handleClick={() => dispatch(userAnswerRequest({id: userID, choose: i + 1}))}
                                        className={"quiz-list__item"}
                                        key={i}
                                        text={text}
                                    />
                                ))}
                            </QuizList>
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--blur-investors">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                <div className="right-side__golo">
                    <div className="right-side__golo-anim">
                        <picture>
                            <source srcSet={goloFireballImageWebp} type="image/webp"/>
                            <source srcSet={goloFireballImage} type="image/png"/>
                            <img className="right-side__golo-img right-side__golo-img--red" src={goloFireballImage}
                                 alt=""/>
                        </picture>
                    </div>
                    <picture>
                        <source srcSet={goloTableRedImageWebp} type="image/webp"/>
                        <source srcSet={goloTableRedImage} type="image/png"/>
                        <img className="right-side__golo-table right-side__golo-table--red" src={goloTableRedImage}
                             alt=""/>
                    </picture>
                </div>
            </RightSide>
        </div>
    );
};

export default memo(GameResources);
