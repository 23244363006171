import {memo, useContext, useMemo, useRef, useState} from "react";
import {InfoList, InfoTime, Preloader, QuizItem, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio, Calls, CallsIncoming, CallsTimer} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";
import {audios} from "@assets/audios";

const GameCalls = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const [startTimer, setStartTimer] = useState(false);
    const [isCalling, setIsCalling] = useState(false);
    const [callingTitle, setCallingTitle] = useState(null);
    const seoCallRef = useRef();
    const teamCallRef = useRef();
    const partnerCallRef = useRef();
    const refs = [seoCallRef, teamCallRef, partnerCallRef];
    const [audioRef, setAudioRef] = useState(null);
    const [playingState, SetPlayingState] = useState(null);
    const audioRefCB = (audio) => setAudioRef(audio);
    const playingStateCB = (isPlaying, setIsPlaying) => SetPlayingState({isPlaying, setIsPlaying})

    const quizText = useMemo(() => [
        t("Get into a big company run by the investor chose with you, worth a total of 1 billion, where you will own 1%."),
        t("To remain an independent project worth $100 million by accepting the investment and diluting the stake to 10% ownership."),
        t("Walk out of the negotiations, remaining in your company worth 10mn."),
    ], []);

    return (
        <div className="game__element">
            <LeftSide>
                <audio ref={seoCallRef}>
                    <source src={audios[lang].seoCallMp3} type="audio/mp3"/>
                    <source src={audios[lang].seoCallWav} type="audio/wav"/>
                </audio>

                <audio ref={teamCallRef}>
                    <source src={audios[lang].teamCallMp3} type="audio/mp3"/>
                    <source src={audios[lang].teamCallWav} type="audio/wav"/>
                </audio>

                <audio ref={partnerCallRef}>
                    <source src={audios[lang].partnerCallMp3} type="audio/mp3"/>
                    <source src={audios[lang].partnerCallWav} type="audio/wav"/>
                </audio>

                {
                    isLoading ? <Preloader/> : <>
                        <Audio
                            handleGetPlayingState={playingStateCB}
                            handleGetRef={audioRefCB}
                            audio={{wav: audios[lang].audioCallsWav, mp3: audios[lang].audioCallsMp3}}
                            subtitle={subtitles[lang].calls}
                            setStartTimer={setStartTimer}
                            autoplay={true}
                        />

                        <Title>
                            <span>{t("On making a decision: ")}</span>
                            <CallsTimer startTimer={startTimer}/>
                        </Title>
                        <QuizList>
                            {quizText.map((text, i) => (
                                <QuizItem
                                    handleClick={() => dispatch(userAnswerRequest({id: userID, solution: i + 1}))}
                                    className={"quiz-list__item"}
                                    key={i}
                                    text={text}/>
                            ))}
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--blur-investors">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                {
                    isCalling ? <CallsIncoming
                            setIsPlaying={playingState.setIsPlaying}
                            audioRef={audioRef}
                            refs={refs}
                            setStartTimer={setStartTimer}
                            callingTitle={callingTitle}
                        /> :
                        <Calls
                            refs={refs}
                            setIsCalling={setIsCalling}
                            setCallingTitle={setCallingTitle}
                        />
                }
            </RightSide>
        </div>
    );
};

export default memo(GameCalls);
