import PropTypes from "prop-types";
import {Button} from "@components/index";
import "./Quiz.scss";
import {showNoInvestors, showOtherChoice} from "@/actionCreators";
import {useDispatch} from "react-redux";

const QuizItem = ({text, className, set, id, mouse, nextPage, noInvestorsTitle, clickState, otherChoiceTitle, noInvestorSubtitle, otherChoiceAudio, otherChoiceSubtitle, investorAudio, handleClick, arrText}) => {
    const eventTrigger = {};

    if (clickState) {
        const dispatch = useDispatch();

        handleClick = () => {
            dispatch(showOtherChoice(otherChoiceTitle, otherChoiceAudio, otherChoiceSubtitle, arrText));
        }
    }

    if (nextPage) {
        const dispatch = useDispatch();

        handleClick = () => {
            dispatch(showNoInvestors(noInvestorsTitle, investorAudio, noInvestorSubtitle));
        }
    }

    if (mouse) {
        const onMouseEnter = () => set(id);
        const onFocus = () => set(id);
        const onMouseLeave = () => set(0);
        const onBlur = () => set(0);

        eventTrigger.onMouseEnter = onMouseEnter;
        eventTrigger.onMouseLeave = onMouseLeave;
        eventTrigger.onFocus = onFocus;
        eventTrigger.onBlur = onBlur;
    }

    return (
        <li {...eventTrigger} className={className}>
            <Button handleClick={handleClick} className="btn btn-link">{text}</Button>
        </li>
    );
};

QuizItem.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    set: PropTypes.func,
    id: PropTypes.number,
    mouse: PropTypes.bool,
    clickState: PropTypes.bool,
    otherChoiceTitle: PropTypes.string,
    otherChoiceAudio: PropTypes.object,
    investorAudio: PropTypes.object,
    handleClick: PropTypes.func.isRequired,
    textRequest: PropTypes.array,
    otherChoiceSubtitle: PropTypes.array,
    noInvestorSubtitle: PropTypes.array,
    // nextPage: PropTypes.node,
    noInvestorsTitle: PropTypes.string,
    arrText: PropTypes.array
};

export default QuizItem;
