import {combineReducers} from "redux";
import {
    PAGE_SHOW_NEXT,
    PAGE_SHOW_BACK,
    PAGE_SHOW_CURRENT,
    PAGE_SHOW_OTHER,
    PAGE_ADD_GRANT_MONEY,
    PAGE_REMOVE_GRANT_MONEY,
    PAGE_ADD_GRANT_TO_MONEY,
    PAGE_ADD_API_RESPONSE,
    PAGE_ADD_NAME,
    PAGE_ADD_PHONE,
    SUCCESS,
    FAIL,
    PAGE_ADD_USER_ANSWER,
    PAGE_ADD_GAME_LOGIC,
    PAGE_CHANGE_GAME_STATUS,
    PAGE_ADD_GAME_MOVE,
    PAGE_ADD_GAME_POINT,
    PAGE_ADD_TIME,
    PAGE_ADD_TIMER_DOWN,
    PAGE_ADD_GAME_ANSWER,
    PAGE_SHOW_NO_INVESTORS,
    PAGE_GET_FINAL_SCREEN,
    PAGE_SEND_CODE,
    START, PAGE_ADD_CODE, PAGE_NEXT_STEP
} from "@/constants";
import map from "@/pagesMap";

const initialState = {
    name: "",
    phone: "",
    pageCounter: 0,
    currentPage: 0,
    title: "",
    audio: null,
    subtitle: null,
    money: 0,
    grantMoney: 100000,
    api: {},
    apiLoading: false,
    gameAnswerLoading: false,
    errorMessage: "",
    errorCode: null,
    userID: 0,
    arrText: [],
    gameStatus: false,
    gameMove: {
        user: 0,
        bot: 0
    },
    gamePoint: {
        user: [],
        bot: []
    },
    botMove: 0,
    gameTime: {ms: 0, s: 0, m: 0, h: 0},
    timerDown: {s: 0, m: 2},
    finalScreen: null
}

const pageReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const {pageCounter, currentPage, grantMoney, gamePoint} = state;

    switch (type) {
        case PAGE_ADD_TIME:
            return {...state, gameTime: payload};
        case PAGE_ADD_TIMER_DOWN:
            return {...state, timerDown: payload};
        case PAGE_SHOW_NEXT:
            return {...state, pageCounter: currentPage + 1, currentPage: currentPage + 1};
        case PAGE_SHOW_OTHER:
            return {
                ...state,
                pageCounter: map[pageCounter].ways,
                title: payload.title,
                audio: payload.audio,
                subtitle: payload.subtitle,
                arrText: payload.arrText
            };
        case PAGE_SHOW_NO_INVESTORS:
            return {
                ...state,
                pageCounter: map[pageCounter].nextWay,
                title: payload.title,
                audio: payload.audio,
                subtitle: payload.subtitle,
            };
        case PAGE_SHOW_CURRENT:
            return {...state, pageCounter: currentPage};
        case PAGE_SHOW_BACK:
            return {...state, pageCounter: currentPage - 1, currentPage: currentPage - 1};
        case PAGE_ADD_GRANT_MONEY:
            return {...state, grantMoney: grantMoney + payload};
        case PAGE_REMOVE_GRANT_MONEY:
            return {...state, grantMoney: grantMoney - payload};
        case PAGE_ADD_GRANT_TO_MONEY:
            return {...state, money: grantMoney};
        case PAGE_ADD_NAME:
            return {...state, name: payload};
        case PAGE_ADD_PHONE:
            return {...state, phone: payload};
        case PAGE_CHANGE_GAME_STATUS:
            return {...state, gameStatus: payload};
        case PAGE_ADD_GAME_MOVE:
            return {
                ...state, gameMove: {
                    user: payload.user,
                    bot: payload.bot
                },
                apiLoading: false,
            };
        case PAGE_ADD_GAME_POINT:
            return {
                ...state, gamePoint: {
                    user: [...gamePoint.user, payload.user],
                    bot: [...gamePoint.bot, payload.bot]
                }
            };

        case PAGE_ADD_API_RESPONSE + START:
            return {...state, apiLoading: true};
        case PAGE_NEXT_STEP:
            return {
                ...state,
                pageCounter: currentPage + payload,
                currentPage: currentPage + payload,
            };
        case PAGE_ADD_API_RESPONSE + SUCCESS:
            return {
                ...state,
                pageCounter: currentPage + payload[1],
                currentPage: currentPage + payload[1],
                errorMessage: "",
                userID: payload[0].message,
                api: payload[0],
                apiLoading: false,
            };
        case PAGE_ADD_API_RESPONSE + FAIL:
            return {...state, errorMessage: payload[0].message, api: payload[0], apiLoading: false};

        case PAGE_ADD_USER_ANSWER + START:
            return {...state, apiLoading: true};
        case PAGE_ADD_USER_ANSWER + SUCCESS:
            return {
                ...state,
                pageCounter: currentPage + 1,
                currentPage: currentPage + 1,
                api: payload,
                apiLoading: false
            };

        case PAGE_ADD_GAME_ANSWER + START:
            return {...state, gameAnswerLoading: true};
        case PAGE_ADD_GAME_ANSWER + SUCCESS:
            return {
                ...state,
                pageCounter: currentPage + 1,
                currentPage: currentPage + 1,
                api: payload,
                gameAnswerLoading: false
            };

        case PAGE_GET_FINAL_SCREEN + START:
            return {...state, apiLoading: false};
        case PAGE_GET_FINAL_SCREEN + SUCCESS:
            return {
                ...state,
                finalScreen: {title: payload.message.title, text: payload.message.text},
                apiLoading: true
            };

        case PAGE_ADD_GAME_LOGIC + START:
            return {...state, apiLoading: true};
        case PAGE_ADD_GAME_LOGIC + SUCCESS:
            return {...state, apiLoading: false, gameMove: {bot: payload.bot, user: payload.user}};

        case PAGE_SEND_CODE + START:
            return {...state, apiLoading: true};
        case PAGE_SEND_CODE + SUCCESS:
            return {...state, api: payload, errorCode: payload.code, apiLoading: false};
        case PAGE_SEND_CODE + FAIL:
            return {...state, api: payload, errorCode: payload.code, apiLoading: false};

        case PAGE_ADD_CODE + START:
            return {...state, apiLoading: true};
        case PAGE_ADD_CODE + SUCCESS:
            return {
                ...state,
                api: payload,
                userID: payload.message,
                pageCounter: currentPage + 2,
                errorCode: payload.code,
                currentPage: currentPage + 2,
                apiLoading: false
            };
        case PAGE_ADD_CODE + FAIL:
            return {
                ...state, errorMessage: payload.message, userID: payload.message, errorCode: payload.code,
                api: payload, apiLoading: false
            };

        default:
            return state;
    }
};

const rootReducer = combineReducers({pageReducer});

export default rootReducer;