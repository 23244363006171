import {Button, FormControl} from "@/components";
import {userAnswerRequest} from "@/api/request";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {gameTimeSelector, userIDSelector} from "@/selectors";
import {deleteUnderscore, getTime} from "@/utils";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

const MathScreenThree = ({profitClickRef, handleProfitChange}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const time = useSelector(gameTimeSelector);
    const [profitInput, setProfitInput] = useState({text: "", helper: "", error: false, showMask: true});
    const profitRef = useRef(null);
    const btnRef = useRef(null);
    const disabledBtn = !deleteUnderscore(profitInput.text);

    useEffect(() => {
        profitRef.current.focus();
    }, []);

    return (
        <>
            <p className="left-side__text">
                <span>{t("Two testers worked on the release of the new version of the product.")}</span>
                <span>{t("They conducted a total of 112 tests.")}</span>
                <span>{t("The first worked 6 hours longer than the second and ran 12 more tests.")}</span>
                <span>{t("But the second tester was 50% faster than the first.")}</span>
                <span>{t("How many hours did the first tester work on this task?")}</span>
            </p>
            <FormControl label={t("Enter answer")} info={profitInput} helper="">
                <InputMask alwaysShowMask={true}
                           className="form-control__input"
                           value={profitInput.text}
                           onChange={(e) => {
                               handleProfitChange(profitInput, setProfitInput, e)
                           }}
                           mask={`999 ${t("hours")}`}>
                    {inputProps =>
                        <input
                            ref={profitRef}
                            onKeyUp={(e) => {
                                profitClickRef(profitInput, profitRef, btnRef, setProfitInput, e)
                            }}
                            {...inputProps}
                        />}
                </InputMask>
            </ FormControl>
            <Button
                disabled={disabledBtn}
                handleClick={() => dispatch(userAnswerRequest({
                    id: userID,
                    number_task: 3,
                    task_answer: deleteUnderscore(profitInput.text),
                    spent_time: getTime(time)
                }))}
                innerRef={btnRef}
                className="btn btn-primary">{t("Next")}</Button>
        </>
    )
}

MathScreenThree.propTypes = {
    profitClickRef: PropTypes.func.isRequired,
    handleProfitChange: PropTypes.func.isRequired
};

export default MathScreenThree;
