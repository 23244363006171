import {Speaker} from "@/components";
import "./Audio.scss";
import {useEffect, useRef, useState} from "react";
import {audioSyncWithText} from "@/utils";
import {AudioControl, AudioProgressBar} from "@/modularComponents";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const Audio = ({audio, autoplay, subtitle, setStartTimer, handleGetRef = () => {}, handleGetPlayingState = () => {}, handleGetMuteState = () => {}}) => {
    const {t} = useTranslation();
    const [percentage, setPercentage] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const audioRef = useRef();
    const audioCurrent = audioRef.current;
    const subtitleRef = useRef();

    useEffect(() => {
        handleGetRef(audioRef);
    }, [audioRef]);

    useEffect(() => {
        handleGetPlayingState(isPlaying, setIsPlaying);
        handleGetMuteState(isMuted, setIsMuted);
    }, []);

    const onChange = (e) => {
        audioCurrent.currentTime = (audioCurrent?.duration / 100) * e.target.value;
        setPercentage(+e.target.value);
    };

    const play = () => {
        if (!isPlaying) {
            setIsPlaying(true);
            setIsMuted(false);
            audioCurrent?.play();
        } else {
            setIsPlaying(false);
            setIsMuted(true);
            audioCurrent?.pause();
        }
    };

    const getCurrDuration = (e) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2);
        const time = e.currentTarget.currentTime;

        setPercentage(+percent);
        setCurrentTime(+time.toFixed(2));
    };

    useEffect(() => {
        audioSyncWithText(audioRef.current, subtitleRef.current, subtitle, setIsPlaying, setStartTimer);
    }, []);

    useEffect(() => {
        if (autoplay) {
            setIsPlaying(true);
            setIsMuted(false);
        } else {
            setIsPlaying(false);
            setIsMuted(true);
        }
    }, [autoplay]);

    return (
        <div className="audio">
            <audio
                onLoadedMetadata={() => {
                    setIsLoading(false);
                }}
                ref={audioRef}
                onTimeUpdate={getCurrDuration}
                onLoadedData={(e) => {
                    setDuration(+e.currentTarget.duration.toFixed(2))
                }}
                autoPlay={autoplay}
                muted={isMuted}
            >
                Your browser does not support the
                <code>audio</code> element.
                <source src={audio.mp3} type="audio/mpeg"/>
                <source src={audio.wav} type="audio/wav"/>
            </audio>
            <Speaker isLoading={isLoading} isPlaying={isPlaying}/>
            <div className="audio__player">
                <div className="audio__subtitles">
                    <div className="audio__subtitles-container">
                        <div className="audio__subtitle">
                            <div className="audio__subtitle-container" ref={subtitleRef}/>
                        </div>
                    </div>
                </div>
                {
                    isLoading ? t("Loading...") : <AudioControl
                        play={play}
                        isPlaying={isPlaying}
                        duration={duration}
                        currentTime={currentTime}
                    >
                        <AudioProgressBar percentage={percentage} onChange={onChange}/>
                    </AudioControl>
                }
            </div>
        </div>
    );
};

Audio.propTypes = {
    audio: PropTypes.object.isRequired,
    subtitle: PropTypes.array.isRequired,
    setStartTimer: PropTypes.func,
    autoplay: PropTypes.bool,
    handleGetRef: PropTypes.func,
    handleGetMuteState: PropTypes.func,
    handleGetPlayingState: PropTypes.func

};

export default Audio;
