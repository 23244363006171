import {memo, useContext, useMemo} from "react";
import {InfoList, InfoTime, Preloader, QuizItem, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameMotivations = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const title = t("Which motivation option will you choose?");
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const audio = {wav: audios[lang].audioSpheresOtherWav, mp3: audios[lang].audioSpheresOtherMp3};
    const subtitle = subtitles[lang].spheresOther;
    const quizText = useMemo(() => [
        {
            text: t("I'll build a clear kpi and reward in proportion to performance and results"),
            clickState: false

        },
        {
            text: t("I'll motivate the guys with ambitious goals, but I won't tie the results to the reward, so the team won't be afraid to take risks"),
            clickState: false
        },
        {
            text: t("I will set ambitious goals and an equally ambitious reward in the event of success, the risks will be shared with the team"),
            clickState: false
        },
        {
            text: t("Handing out ownership stakes in the project to the team"),
            clickState: false
        },
        {
            text: t("Suggest a team's choice of options"),
            clickState: false
        },
        {
            text: t("Your Own Option"),
            clickState: true
        }
    ], []);

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioMotivationsWav, mp3: audios[lang].audioMotivationsMp3}}
                               subtitle={subtitles[lang].motivations} autoplay={true}/>
                        <Title>{title}</Title>
                        <QuizList>
                            {quizText.map((el, i) => (
                                <QuizItem
                                    handleClick={() => dispatch(userAnswerRequest({
                                        id: userID,
                                        type_motivation: i + 1
                                    }))}
                                    className={"quiz-list__item"}
                                    key={i}
                                    text={el.text}
                                    clickState={el.clickState}
                                    otherChoiceTitle={title}
                                    otherChoiceAudio={audio}
                                    otherChoiceSubtitle={subtitle}
                                    arrText={["type_motivation", "type_motivation_text"]}/>
                            ))}
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--laboratory">
                <InfoList>
                    <InfoTime/>
                </InfoList>
            </RightSide>
        </div>
    );
};

export default memo(GameMotivations);
