import {Button, Preloader, Title} from "@/components/index";
import {MiniGameTable} from "@modularComponents/index";
import icons from "@assets/icons";
import "./MiniGame.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {gameLogicRequest} from "@/api/request";
import {apiLoadingSelector, gameMoveSelector, gamePointSelector, gameStatusSelector} from "@/selectors";
import {addGamePoint, changeGameStatus} from "@/actionCreators";
import {triangleWav, rectangleWav, triangleMp3, rectangleMp3} from "@assets/audios";

const MiniGame = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const polygonMove = 1;
    const rectangleMove = 2;
    let userPoint = 0;
    let botPoint = 0;
    const plusThreePoint = 3;
    const minusThreePoint = -3;
    const plusFivePoint = 5;
    const minusFivePoint = -5;
    const gameStatus = useSelector(gameStatusSelector);
    const gamePoint = useSelector(gamePointSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const [score, setScore] = useState(0);
    const [scoreBot, setScoreBot] = useState(0);
    const [keyCount, setKeyCount] = useState(0);
    const [prevRequest, setPrevRequest] = useState({});
    const gameLogic = {
        first_answer: 2,
        second_answer: 2,
        third_answer: 2,
        fourth_answer: 2,
        fifth_answer: 5,
        sixth_answer: 6,
        seventh_answer: 7,
    };
    const gameMove = useSelector(gameMoveSelector);
    const [userMove, setUserMove] = useState([]);
    const [botMove, setBotMove] = useState([]);
    const triangleAudioRef = useRef();
    const rectangleAudioRef = useRef();

    useEffect(() => {
        if (keyCount > 0) {
            setUserMove([...userMove, gameMove.user]);
            setBotMove([...botMove, gameMove.bot]);

            if (gameMove.user === polygonMove && gameMove.bot === polygonMove) {
                userPoint = plusThreePoint;
                botPoint = plusThreePoint;
            }

            if (gameMove.user === rectangleMove && gameMove.bot === polygonMove) {
                userPoint = plusFivePoint;
                botPoint = minusFivePoint;
            }

            if (gameMove.user === polygonMove && gameMove.bot === rectangleMove) {
                userPoint = minusFivePoint;
                botPoint = plusFivePoint;
            }

            if (gameMove.user === rectangleMove && gameMove.bot === rectangleMove) {
                userPoint = minusThreePoint;
                botPoint = minusThreePoint;
            }

            setScoreBot(score => score + botPoint);
            setScore(score => score + userPoint);

            dispatch(addGamePoint({
                user: userPoint,
                bot: botPoint
            }));
        }

        keyCount === 7 ?
            dispatch(changeGameStatus(true))
            : null;
    }, [gameMove])

    const gameRequest = move => {
        const arrKeys = Object.keys(gameLogic);
        const key = arrKeys[keyCount];
        setKeyCount(key => key + 1);

        dispatch(gameLogicRequest({...prevRequest, [key]: move, "need_result": gameLogic[key]}, move, key));

        setPrevRequest({...prevRequest, [key]: move});
    };

    const handleClickPolygon = () => {
        gameRequest(polygonMove);
        triangleAudioRef.current?.play();
    };

    const handleClickRectangle = () => {
        gameRequest(rectangleMove);
        rectangleAudioRef.current?.play();
    };

    const checkGamePoint = point => point.map((item, id) => {
        if (item === plusThreePoint) {
            return <div key={id} className="mini-game-table__player-turn">
                &#43; {plusThreePoint}
            </div>
        }

        if (item === minusThreePoint) {
            return <div key={id} className="mini-game-table__player-turn">
                &#8722; {plusThreePoint}
            </div>
        }

        if (item === plusFivePoint) {
            return <div key={id} className="mini-game-table__player-turn">
                &#43; {plusFivePoint}
            </div>
        }

        if (item === minusFivePoint) {
            return <div key={id} className="mini-game-table__player-turn">
                &#8722; {plusFivePoint}
            </div>
        }
    });

    const checkGameMove = moves => moves.map((item, id) => {
        if (item === polygonMove) {
            return (
                <div key={id} className="mini-game-table__player-turn">
                    <icons.Polygon/>
                </div>
            )
        }

        if (item === rectangleMove) {
            return (
                <div key={id} className="mini-game-table__player-turn">
                    <icons.Rectangle/>
                </div>
            )
        }
    });

    return (
        <div className="mini-game">
            {
                isLoading ? <Preloader/> : <>
                    <Title className="mini-game__title">{t("Your move!")}</Title>
                    {
                        gameStatus ? (
                            <>
                                <p className="mini-game__text">
                                    {
                                        t("As a result of the struggle in the market") + " "
                                    }
                                    {
                                        score > 0 ? t("You have earned", {score: score}) : null
                                    }
                                    {
                                        score < 0 ? t("You incurred a loss", {score: score}) : null
                                    }
                                    {
                                        score === 0 ? t("You didn't manage to earn anything") : null
                                    }
                                </p>
                                {
                                    score > 0 ? <p className="mini-game__text">{t("Good result.")}</p> : null
                                }
                            </>
                        ) : null
                    }
                    <div className="mini-game__btn-group">
                        {
                            !gameStatus ? (
                                <>
                                    <Button handleClick={handleClickPolygon} className="btn-game btn-game--orange">
                                        <icons.Polygon/>
                                    </Button>

                                    <Button handleClick={handleClickRectangle} className="btn-game btn-game--blue">
                                        <icons.Rectangle/>
                                    </Button>
                                </>
                            ) : null
                        }
                    </div>
                    <MiniGameTable>
                        <div className="mini-game-table__row">
                            <div className="mini-game-table__header">
                                <div className="mini-game-table__title">{t("Combination of moves")}</div>
                            </div>

                            <div className="mini-game-table__content">
                                <div className="mini-game-table__player">
                                    <div className="mini-game-table__text">{t("You")}</div>

                                    <div className="mini-game-table__player-turns">
                                        {checkGameMove(userMove)}
                                    </div>
                                </div>

                                <div className="mini-game-table__player">
                                    <div className="mini-game-table__text">{t("Second player")}</div>

                                    <div className="mini-game-table__player-turns">
                                        {checkGameMove(botMove)}
                                    </div>
                                </div>
                            </div>

                            <div className="mini-game-table__footer">
                                <div className="mini-game-table__text">
                                    {t("Final result")}
                                </div>
                            </div>
                        </div>

                        <div className="mini-game-table__row">
                            <div className="mini-game-table__header">
                                <div className="mini-game-table__title">{t("Result")}</div>
                            </div>

                            <div className="mini-game-table__content">
                                <div className="mini-game-table__player">
                                    <div className="mini-game-table__text">{t("You")}</div>

                                    <div className="mini-game-table__player-turns">
                                        {checkGamePoint(gamePoint.user)}
                                    </div>
                                </div>

                                <div className="mini-game-table__player">
                                    <div className="mini-game-table__text">{t("Second player")}</div>

                                    <div className="mini-game-table__player-turns">
                                        {checkGamePoint(gamePoint.bot)}
                                    </div>
                                </div>
                            </div>

                            <div className="mini-game-table__footer">
                                <div className="mini-game-table__player mini-game-table__player-info">
                                    {score > 0 ? `+${score} ` + t("million") : score + " " + t("million")}
                                </div>

                                <div className="mini-game-table__player mini-game-table__player-info">
                                    {scoreBot > 0 ? `+${scoreBot} ` + t("million") : scoreBot + " " + t("million")}
                                </div>
                            </div>
                        </div>
                    </MiniGameTable>
                </>
            }
            <audio ref={triangleAudioRef}>
                <source src={triangleMp3} type="audio/mp3"/>
                <source src={triangleWav} type="audio/wav"/>
            </audio>
            <audio ref={rectangleAudioRef}>
                <source src={rectangleMp3} type="audio/mp3"/>
                <source src={rectangleWav} type="audio/wav"/>
            </audio>
        </div>
    );
};

export default MiniGame;
