import React from 'react';
import icons from '@assets/icons';
import './Preloader.scss';
import {useTranslation} from "react-i18next";

const Preloader = () => {
    const {t} = useTranslation();
    return (
        <div className="preloader">
            <div className="preloader__outer">
                <icons.PreloaderOuter/>
            </div>
            <span>{t("Loading...")}</span>
            <div className="preloader__inner">
                <icons.PreloaderInnerCircle/>
                <icons.PreloaderInnerHalf/>
            </div>
        </div>
    );
};

export default Preloader;
