const PAGE_SHOW_NEXT = "PAGE/PAGE_SHOW_NEXT";
const PAGE_SHOW_OTHER = "PAGE/PAGE_SHOW_OTHER";
const PAGE_SHOW_CURRENT = "PAGE/PAGE_SHOW_CURRENT";
const PAGE_SHOW_NO_INVESTORS = "PAGE/PAGE_SHOW_NO_INVESTORS";
const PAGE_SHOW_BACK = "PAGE/PAGE_SHOW_BACK";
const PAGE_ADD_GRANT_MONEY = "PAGE/PAGE_ADD_GRANT_MONEY";
const PAGE_REMOVE_GRANT_MONEY = "PAGE/PAGE_REMOVE_GRANT_MONEY";
const PAGE_ADD_GRANT_TO_MONEY = "PAGE/PAGE_ADD_GRANT_TO_MONEY";
const PAGE_NEXT_STEP = "PAGE/PAGE_NEXT_STEP";
const PAGE_ADD_API_RESPONSE = "PAGE/PAGE_ADD_API_RESPONSE";
const PAGE_ADD_USER_ANSWER = "PAGE/PAGE_ADD_USER_ANSWER";
const PAGE_ADD_GAME_LOGIC = "PAGE/PAGE_ADD_GAME_LOGIC";
const PAGE_ADD_GAME_ANSWER = "PAGE/PAGE_ADD_GAME_ANSWER";
const PAGE_CHANGE_GAME_STATUS = "PAGE/PAGE_CHANGE_GAME_STATUS";
const PAGE_ADD_GAME_MOVE = "PAGE/PAGE_ADD_GAME_MOVE";
const PAGE_ADD_GAME_POINT = "PAGE/PAGE_ADD_GAME_POINT";
const PAGE_ADD_NAME = "PAGE/PAGE_ADD_NAME";
const PAGE_ADD_PHONE = "PAGE/PAGE_ADD_PHONE";
const PAGE_ADD_TIME = "PAGE/PAGE_ADD_TIME";
const PAGE_ADD_TIMER_DOWN = "PAGE/PAGE_ADD_TIMER_DOWN";
const PAGE_GET_FINAL_SCREEN = "PAGE/PAGE_GET_FINAL_SCREEN";
const PAGE_SEND_CODE = "PAGE/PAGE_SEND_CODE";
const PAGE_ADD_CODE = "PAGE/PAGE_ADD_CODE";
const SUCCESS = "_SUCCESS";
const START = "_START";
const FAIL = "_FAIL";

export {
    PAGE_SHOW_NEXT,
    PAGE_SHOW_OTHER,
    PAGE_SHOW_CURRENT,
    PAGE_SHOW_BACK,
    PAGE_ADD_GRANT_MONEY,
    PAGE_REMOVE_GRANT_MONEY,
    PAGE_ADD_GRANT_TO_MONEY,
    PAGE_CHANGE_GAME_STATUS,
    PAGE_SHOW_NO_INVESTORS,
    PAGE_GET_FINAL_SCREEN,
    PAGE_ADD_CODE,
    PAGE_ADD_GAME_MOVE,
    PAGE_ADD_API_RESPONSE,
    PAGE_ADD_USER_ANSWER,
    PAGE_ADD_GAME_POINT,
    PAGE_ADD_GAME_LOGIC,
    PAGE_ADD_NAME,
    PAGE_ADD_PHONE,
    PAGE_ADD_TIME,
    PAGE_ADD_TIMER_DOWN,
    PAGE_ADD_GAME_ANSWER,
    PAGE_SEND_CODE,
    START,
    SUCCESS,
    FAIL,
    PAGE_NEXT_STEP,
};