import PropTypes from "prop-types";
import "./Quiz.scss";

const QuizList = ({ children }) => {
   return <ul className="quiz-list">{children}</ul>;
};

QuizList.propTypes = {
   children: PropTypes.node.isRequired,
};

export default QuizList;
