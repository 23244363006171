import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./Checkbox.scss";

const Checkbox = ({checkStatus, text, handleChange, id}) => {
    const [checked, setChecked] = useState(false);

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            handleChange(checked, setChecked, id)
        }
    }

    useEffect(() => {
            if (checkStatus !== undefined) {
                setChecked(checkStatus);
            }
        }
    , [checkStatus])

    return (
        <label tabIndex={0} onKeyUp={handleKeyUp} className="checkbox__label">
            <input
                className="checkbox__item "
                type="checkbox"
                checked={checked}
                onChange={() => handleChange(checked, setChecked, id)}
            />
            <span className="checkbox__indicator">
               <span className="checkbox__checker"/>
            </span>
            {text}
        </label>
    );
};

Checkbox.propTypes = {
    text: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.number
};

export default Checkbox;
