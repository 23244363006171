import {memo, useContext, useMemo} from "react";
import {InfoList, InfoTime, Preloader, QuizItem, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameInvestors = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const dispatch = useDispatch();
    const title = t("Which investor would you prefer?");
    const audioSteveJobs = {wav: audios[lang].audioInvestorSteveJobsWav, mp3: audios[lang].audioInvestorSteveJobsMp3};
    const audioOther = {wav: audios[lang].audioInvestorsOtherWav, mp3: audios[lang].audioInvestorsOtherMp3};
    const subtitleOther = subtitles[lang].investorsOther;
    const subtitleNoInvestorSteveJobs = subtitles[lang].noInvestorSteveJobs;
    const quizText = useMemo(() => [
        {
            text: t("Mark Zuckerberg"),
            clickState: false,
            noInvestors: false,
            noInvestorsTitle: null,
        },
        {
            text: t("Jeff Bezos"),
            clickState: false,
            noInvestors: false,
            noInvestorsTitle: null,
        },
        {
            text: t("Elon Musk"),
            clickState: false,
            noInvestors: false,
            noInvestorsTitle: null,
        },
        {
            text: t("Pavel Durov"),
            clickState: false,
            noInvestors: false,
            noInvestorsTitle: null,
        },
        {
            text: t("Steve Jobs"),
            clickState: false,
            noInvestors: true,
            noInvestorsTitle: t("Steve can't do your project now, choose another investor"),
        },
        {
            text: t("Your Own Investor"),
            clickState: true,
            noInvestors: false,
            noInvestorsTitle: null,
        }
    ], []);

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioInvestorsWav, mp3: audios[lang].audioInvestorsMp3}}
                               subtitle={subtitles[lang].investors}
                               autoplay={true}/>
                        <Title>{title}</Title>
                        <QuizList>
                            {quizText.map((el, i) => (
                                <QuizItem
                                    handleClick={() => dispatch(userAnswerRequest({id: userID, type_investor: i + 1}))}
                                    key={i}
                                    text={el.text}
                                    nextPage={el.noInvestors}
                                    noInvestorsTitle={el.noInvestorsTitle}
                                    clickState={el.clickState}
                                    otherChoiceTitle={title}
                                    otherChoiceAudio={audioOther}
                                    investorAudio={audioSteveJobs}
                                    otherChoiceSubtitle={subtitleOther}
                                    noInvestorSubtitle={subtitleNoInvestorSteveJobs}
                                    arrText={["type_investor", "type_investor_text"]}/>
                            ))}
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--investors">
                <InfoList>
                    <InfoTime/>
                </InfoList>
            </RightSide>
        </div>
    );
};

export default memo(GameInvestors);
