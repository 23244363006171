import "@assets/styles/index.scss";
import {memo, useState, useEffect, createContext} from "react";
import "./App.scss";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import i18n from "i18next";
import Start from "@/Start";
import {checkResponse} from "@/api/request";

export const AppContext = createContext({});

const App = () => {
    const location = useLocation();

    const [lang, setLang] = useState("ua");
    const [playedStatus, setPlayedStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [responseId, setResponseId] = useState("");

    const context = {
        lang,
        setLang,
        isLoading,
        setIsLoading,
        playedStatus,
        setPlayedStatus,
        responseId,
        setResponseId,
    };

    useEffect(async () => {
        const id = location.search.split("?responseid=")[1];

        if (id) {
            try {
                const response = await checkResponse(id);

                if (response?.data?.message === true) {
                    setResponseId(id)
                } else {
                    setPlayedStatus(true);
                }
            } catch (e) {
                setPlayedStatus(true);
            }
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        i18n.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    }, []);

    return (
        <div className="App">
            <AppContext.Provider value={context}>
                <Routes>
                    <Route path="/" element={<Start/>}/>

                    <Route path="*" element={<Navigate to={"/"}/>}/>
                </Routes>
            </AppContext.Provider>
        </div>
    );
}

export default memo(App);
