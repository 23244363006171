import {memo, useContext, useMemo, useState} from "react";
import {InfoList, InfoTime, Preloader, QuizItem, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import {
    goloImage0,
    goloImage0Webp,
    goloImage1,
    goloImage10,
    goloImage10Webp,
    goloImage11,
    goloImage11Webp,
    goloImage1Webp,
    goloImage2,
    goloImage2Webp,
    goloImage3,
    goloImage3Webp,
    goloImage4,
    goloImage4Webp,
    goloImage5,
    goloImage5Webp,
    goloImage6,
    goloImage6Webp,
    goloImage7,
    goloImage7Webp,
    goloImage8,
    goloImage8Webp,
    goloImage9,
    goloImage9Webp,
    goloTableImage,
    goloTableImageWebp
} from "@assets/images";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {animated, useTransition} from "react-spring";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {userAnswerRequest} from "@/api/request";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameSpheres = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const title = t("Which IT field is closest to you for starting out?");
    const audio = {wav: audios[lang].audioSpheresOtherWav, mp3: audios[lang].audioSpheresOtherMp3};
    const subtitle = subtitles[lang].spheresOther;
    const [img, setImg] = useState(0);

    const imgs = useMemo(() => ({
        "0": {png: goloImage0, webp: goloImage0Webp},
        "1": {png: goloImage1, webp: goloImage1Webp},
        "2": {png: goloImage2, webp: goloImage2Webp},
        "3": {png: goloImage3, webp: goloImage3Webp},
        "4": {png: goloImage4, webp: goloImage4Webp},
        "5": {png: goloImage5, webp: goloImage5Webp},
        "6": {png: goloImage6, webp: goloImage6Webp},
        "7": {png: goloImage7, webp: goloImage7Webp},
        "8": {png: goloImage8, webp: goloImage8Webp},
        "9": {png: goloImage9, webp: goloImage9Webp},
        "10": {png: goloImage10, webp: goloImage10Webp},
        "11": {png: goloImage11, webp: goloImage11Webp},
    }), []);

    const quizText = useMemo(() => [
        {
            text: t("Marketing/Promotion"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Social Media"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Marketplace"),
            clickState: false,
            mouse: true
        },
        {
            text: t("SaaS"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Artificial Intelligence"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Automotive"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Healthy Lifestyle"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Games"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Medicine"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Blockchain"),
            clickState: false,
            mouse: true
        },
        {
            text: t("Your Own Option"),
            clickState: true,
            mouse: true
        },
    ], []);

    const transitions = useTransition(imgs[img], {
        initial: false,
        from: {opacity: 0, transform: `translate(-50%) scale(1.5)`},
        leave: {opacity: 0, transform: `translate(-50%) scale(1.5)`},
        enter: {opacity: 1, transform: `translate(-50%) scale(1)`},
        config: {duration: 300},
    });

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioSpheresWav, mp3: audios[lang].audioSpheresMp3}} subtitle={subtitles[lang].spheres}
                               autoplay={true}/>
                        <Title>{title}</Title>
                        <QuizList>
                            {quizText.map((el, i) => (
                                <QuizItem key={i}
                                          handleClick={() => dispatch(userAnswerRequest({
                                              id: userID,
                                              it_sphere: i + 1
                                          }))}
                                          set={setImg}
                                          mouse={el.mouse}
                                          text={el.text}
                                          id={i + 1}
                                          clickState={el.clickState}
                                          otherChoiceTitle={title}
                                          otherChoiceAudio={audio}
                                          otherChoiceSubtitle={subtitle}
                                          arrText={["it_sphere", "it_sphere_text"]}
                                />
                            ))}
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--blur">
                <InfoList>
                    <InfoTime/>
                </InfoList>
                <div className="right-side__golo">
                    <div className="right-side__golo-anim">
                        {
                            transitions((style, item) => {
                                    return <picture>
                                        <source srcSet={item.webp} type="image/webp"/>
                                        <source srcSet={item.png} type="image/png"/>
                                        <animated.img
                                            style={style}
                                            className="right-side__golo-img"
                                            src={item.png}
                                            alt=""/>
                                    </picture>

                                }
                            )
                        }
                    </div>
                    <picture>
                        <source srcSet={goloTableImageWebp} type="image/webp"/>
                        <source srcSet={goloTableImage} type="image/png"/>
                        <img className="right-side__golo-table" src={goloTableImage} alt=""/>
                    </picture>
                </div>
            </RightSide>
        </div>
    );
};

export default memo(GameSpheres);
