import {
    speakerGif,
    speakerGifImage,
    speakerGifImageWebp,
    speakerImage,
    speakerImageWebp,
} from "@assets/images";
import "./Speaker.scss";

const Speaker = ({isPlaying, isLoading}) => {
    return <>
        {
            isLoading ? null : <>
                {
                    isPlaying ?
                        <img src={speakerGif} className="speaker-gif" width="188" height="158" alt="Speaker effect"/> :
                        <picture>
                            <source srcSet={speakerGifImageWebp} type="image/webp"/>
                            <source srcSet={speakerGifImage} type="image/png"/>
                            <img src={speakerGifImage} className="speaker-gif" width="188" height="158"
                                 alt="Speaker effect"/>
                        </picture>
                }
                <picture>
                    <source srcSet={speakerImageWebp} type="image/webp"/>
                    <source srcSet={speakerImage} type="image/png"/>
                    <img src={speakerImage} className="speaker" width="188" height="158" alt="Speaker"/>
                </picture>
            </>
        }
    </>;
};

export default Speaker;
