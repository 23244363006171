const pageCounterSelector = state => state.pageReducer.pageCounter;
const pageCurrentSelector = state => state.pageReducer.currentPage;
const titleSelector = state => state.pageReducer.title;
const moneySelector = state => state.pageReducer.money;
const grantMoneySelector = state => state.pageReducer.grantMoney;
const apiSelector = state => state.pageReducer.api;
const nameSelector = state => state.pageReducer.name;
const phoneSelector = state => state.pageReducer.phone;
const errorMsgSelector = state => state.pageReducer.errorMessage;
const userIDSelector = state => state.pageReducer.userID;
const arrTextSelector = state => state.pageReducer.arrText;
const gameStatusSelector = state => state.pageReducer.gameStatus;
const gameMoveSelector = state => state.pageReducer.gameMove;
const gamePointSelector = state => state.pageReducer.gamePoint;
const botMoveSelector = state => state.pageReducer.botMove;
const gameTimeSelector = state => state.pageReducer.gameTime;
const timerDownSelector = state => state.pageReducer.timerDown;
const finalScreenSelector = state => state.pageReducer.finalScreen;
const audioSelector = state => state.pageReducer.audio;
const subtitleSelector = state => state.pageReducer.subtitle;
const apiLoadingSelector = state => state.pageReducer.apiLoading;
const gameAnswerLoadingSelector = state => state.pageReducer.gameAnswerLoading;
const errorCodeSelector = state => state.pageReducer.errorCode;

export {
    gameAnswerLoadingSelector,
    apiLoadingSelector,
    pageCounterSelector,
    titleSelector,
    audioSelector,
    moneySelector,
    grantMoneySelector,
    pageCurrentSelector,
    gameMoveSelector,
    apiSelector,
    nameSelector,
    phoneSelector,
    errorMsgSelector,
    userIDSelector,
    arrTextSelector,
    gameStatusSelector,
    gamePointSelector,
    botMoveSelector,
    gameTimeSelector,
    timerDownSelector,
    finalScreenSelector,
    errorCodeSelector,
    subtitleSelector
};