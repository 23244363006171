import {memo, useContext, useMemo} from "react";
import {InfoList, InfoTime, Preloader, QuizItem, QuizList, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import "@/i18n";
import {useTranslation} from "react-i18next";
import {userAnswerRequest} from "@/api/request";
import {useDispatch, useSelector} from "react-redux";
import {apiLoadingSelector, userIDSelector} from "@/selectors";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {AppContext} from "@/App";

const GameMakeMove = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userID = useSelector(userIDSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const quizText = useMemo(() => [
        t("You won't react, you can't bring back the past, what's done is done."),
        t("Respond for \"lowering\" your credibility and publicly disclose the subject."),
        t("Find a way to talk privately to convey your point that everything was done right in that situation, but now this kind of behavior is not good for anyone."),
    ], []);

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Audio audio={{wav: audios[lang].audioMakeMoveWav, mp3: audios[lang].audioMakeMoveMp3}}
                               subtitle={subtitles[lang].makeMove}
                               autoplay={true}/>
                        <Title>{t("What will you do?")}</Title>
                        <QuizList>
                            {quizText.map((text, i) => (
                                <QuizItem
                                    handleClick={() => dispatch(userAnswerRequest({id: userID, what_will_do: i + 1}))}
                                    className={"quiz-list__item"}
                                    key={i}
                                    text={text}/>
                            ))}
                        </QuizList>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--investors2">
                <InfoList>
                    <InfoTime/>
                </InfoList>
            </RightSide>
        </div>
    );
};

export default memo(GameMakeMove);
