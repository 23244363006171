import {Title} from "@/components/index";
import icons from "@assets/icons";
import "./Calls.scss";
import "@/i18n";
import {CallsControl} from "@/modularComponents";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {timerRun} from "@/utils";
import PropTypes from "prop-types";

const CallsIncoming = ({callingTitle, setStartTimer, refs, audioRef, setIsPlaying}) => {
    const {t} = useTranslation();
    const completedTitle = t("The call is complete");
    const [control, setControl] = useState(true);
    const [intrv, setIntrv] = useState();
    const [time, setTime] = useState({ms: 0, s: 0, m: 0, h: 0});

    useEffect(() => {
        setStartTimer(true);
        setIsPlaying(false);
        audioRef.current.pause();
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => timerRun(time, (updatedMs, updatedS, updatedM, updatedH) => setTime({
            ms: updatedMs,
            s: updatedS,
            m: updatedM,
            h: updatedH
        })), 10);
        setIntrv(timer);

        return () => clearTimeout(timer);
    }, [time]);

    return (
        <div className="calls calls--incoming">
            <div className="calls__incoming">
                <div className="calls__icon">
                    <icons.User/>
                </div>
                <Title className="calls__title calls__title--incoming">{control ? t(callingTitle) : completedTitle}</Title>
                <div className="calls__timer">
                    {
                        time.h > 0 ?
                            <>
                                <span>{time.h}&#58;</span>
                                <span>{time.m >= 10 ? time.m : "0" + time.m}&#58;</span>
                            </> :
                            <span>{time.m}&#58;</span>
                    }

                    <span>{time.s >= 10 ? time.s : "0" + time.s}</span>
                </div>
                {control ? <CallsControl refs={refs} intrv={intrv} setControl={setControl}/> : null}
            </div>
        </div>
    );
};

CallsIncoming.propTypes = {
    callingTitle: PropTypes.string.isRequired,
    setStartTimer: PropTypes.func.isRequired,
    refs: PropTypes.array.isRequired,
    audioRef: PropTypes.any.isRequired,
    setIsPlaying: PropTypes.func.isRequired
};

export default CallsIncoming;
