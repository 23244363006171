import {memo, useEffect, useState} from "react";
import {getRandomInt} from "@/utils";
import MathScreenTwo from "@modularComponents/MathScreens/MathScreenTwo";
import MathScreenThree from "@modularComponents/MathScreens/MathScreenThree";
import MathScreenOne from "@modularComponents/MathScreens/MathScreenOne";
import {Title} from "@/components";
import {useTranslation} from "react-i18next";

const MathScreen = () => {
    const {t} = useTranslation();
    const [randomMathScreen, setRandomMathScreen] = useState(null);

    useEffect(() => {
        setRandomMathScreen(getRandomInt(3))
    }, []);

    const profitClickRef = (profitInput, profitRef, btnRef, setProfitInput, e) => {
        if (e.target.value) {
            if (e.keyCode === 13 && !profitInput.error) {
                profitRef.current.blur();
                btnRef.current.click();
            }
        } else {
            setProfitInput({...profitInput, helper: "The field must not be empty", error: true});
        }
    };

    const handleProfitChange = (profitInput, setProfitInput, e) => {
        const value = e.target.value;
        value.length ?
            setProfitInput({...profitInput, text: value, helper: "", error: false}) :
            setProfitInput({...profitInput, text: value, helper: "Поле не должно быть пустым", error: true})
    };

    return (
        <>
            {
                randomMathScreen === 0 ?
                    <>
                        <Title>{t("What are your profits?")}</Title>
                        <MathScreenOne
                            profitClickRef={profitClickRef}
                            handleProfitChange={handleProfitChange}
                        />
                    </> : null
            }
            {
                randomMathScreen === 1 ?
                    <>
                        <Title>{t("How much money do you have?")}</Title>
                        <MathScreenTwo
                            profitClickRef={profitClickRef}
                            handleProfitChange={handleProfitChange}
                        />
                    </>  : null
            }
            {
                randomMathScreen === 2 ?
                    <>
                        <Title>{t("What number of hours?")}</Title>
                        <MathScreenThree
                            profitClickRef={profitClickRef}
                            handleProfitChange={handleProfitChange}
                        />
                    </> : null
            }
        </>
    )
}

export default memo(MathScreen);
