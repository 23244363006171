import i18n from "i18next";
import {initReactI18next} from 'react-i18next';

import ru from '@assets/locales/ru/index.json';
import es from '@assets/locales/es/index.json';
import ua from '@assets/locales/ua/index.json';

// the translations
const resources = {
    ru: {
        translation: ru
    },
    ua: {
        translation: ua
    },
    es: {
        translation: es
    }
};

i18n
    .use(initReactI18next)
    .init({
        lng: "ru",
        resources,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        keySeparator: false,
        nsSeparator: "|"
    });

export default i18n;
