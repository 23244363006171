import PropTypes from "prop-types";
import "./MiniGameTable.scss";

const MiniGameTable = ({ children }) => {
   return <div className="mini-game-table">{children}</div>;
};

MiniGameTable.propTypes = {
   children: PropTypes.node.isRequired,
};

export default MiniGameTable;
