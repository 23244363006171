import {
    PAGE_SHOW_NEXT,
    PAGE_SHOW_OTHER,
    PAGE_SHOW_CURRENT,
    PAGE_SHOW_BACK,
    PAGE_ADD_GRANT_MONEY,
    PAGE_REMOVE_GRANT_MONEY,
    PAGE_ADD_GRANT_TO_MONEY,
    PAGE_ADD_NAME,
    PAGE_ADD_PHONE,
    PAGE_CHANGE_GAME_STATUS,
    PAGE_ADD_GAME_MOVE,
    PAGE_ADD_GAME_POINT,
    PAGE_ADD_TIME, PAGE_ADD_TIMER_DOWN, PAGE_SHOW_NO_INVESTORS
} from "@constants/index";
import {timerRun} from "@/utils";
const changePage = () => ({type: PAGE_SHOW_NEXT});
const showOtherChoice = (title, audio, subtitle,  arrText) => ({type: PAGE_SHOW_OTHER, payload: {title, audio, subtitle, arrText}});
const showNoInvestors = (title, audio, subtitle) => ({type: PAGE_SHOW_NO_INVESTORS, payload: {title, audio, subtitle}});
const showCurrentPage = () => ({type: PAGE_SHOW_CURRENT});
const moveBack = () => ({type: PAGE_SHOW_BACK});
const addGrantMoney = moneyPrize => ({type: PAGE_ADD_GRANT_MONEY, payload: moneyPrize});
const removeGrantMoney = moneyPrize => ({type: PAGE_REMOVE_GRANT_MONEY, payload: moneyPrize});
const addGrantToMoney = () => ({type: PAGE_ADD_GRANT_TO_MONEY});
const addName = name => ({type: PAGE_ADD_NAME, payload: name});
const addPhone = phone => ({type: PAGE_ADD_PHONE, payload: phone});
const changeGameStatus = status => ({type: PAGE_CHANGE_GAME_STATUS, payload: status});
const addGameMove = moves => ({type: PAGE_ADD_GAME_MOVE, payload: moves});
const addGamePoint = point => ({type: PAGE_ADD_GAME_POINT, payload: point});
const addTime = timer => {
    return timerRun(timer, (updatedMs, updatedS, updatedM, updatedH) => ({
        type: PAGE_ADD_TIME,
        payload: {ms: updatedMs, s: updatedS, m: updatedM, h: updatedH}
    }))
};
const addTimerDown = (timer, result) => {
    let {s: updatedS, m: updatedM} = timer;
    if (updatedS > 0) {
        updatedS--;
    }
    if (updatedS === 0) {
        if (updatedM === 0) {
            result();
            return ({
                type: PAGE_ADD_TIMER_DOWN,
                payload: {s: updatedS, m: updatedM}
            })
        } else {
            updatedM--;
            updatedS = 59;
        }
    }

    return ({
        type: PAGE_ADD_TIMER_DOWN,
        payload: {s: updatedS, m: updatedM}
    })
}

export {
    changePage,
    showOtherChoice,
    showCurrentPage,
    moveBack,
    addGrantMoney,
    removeGrantMoney,
    addGrantToMoney,
    changeGameStatus,
    addGameMove,
    addGamePoint,
    addName,
    addPhone,
    addTime,
    addTimerDown,
    showNoInvestors
};