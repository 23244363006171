import PropTypes from "prop-types";
import "./LeftSide.scss";

const LeftSide = ({ children }) => {
   return (
      <div className="left-side">
         <div className="left-side__container">{children}</div>
      </div>
   );
};

LeftSide.propTypes = {
   children: PropTypes.node.isRequired,
};

export default LeftSide;
