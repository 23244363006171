import {Button, FormControl, Preloader, Title,} from "@components/index";
import {LeftSide, RightSide} from "@layout/index";
import {Audio} from "@modularComponents/index";
import "./Game.scss";
import '@/i18n';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {moveBack} from "@/actionCreators";
import icons from "@assets/icons";
import {memo, useContext, useEffect, useRef, useState} from "react";
import {audios} from "@assets/audios";
import subtitles from "@/subtitles";
import {loginRequest} from "@/api/request";
import {
    apiLoadingSelector,
    errorCodeSelector,
    errorMsgSelector,
    nameSelector,
    phoneSelector,
    userIDSelector
} from "@/selectors";
import {PAGE_NEXT_STEP} from "@/constants";
import {AppContext} from "@/App";

const GameCode = () => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [codeInput, setCodeInput] = useState({text: "", helper: "", error: false});
    const id = useSelector(userIDSelector);
    const name = useSelector(nameSelector);
    const phone = useSelector(phoneSelector);
    const errorCode = useSelector(errorCodeSelector);
    const errorMsg = useSelector(errorMsgSelector);
    const isLoading = useSelector(apiLoadingSelector);
    const codeRef = useRef(null);
    const btnRef = useRef(null);
    const disabledBtn = !codeInput.text;

    useEffect(() => {
        codeRef.current.focus()
    }, []);

    useEffect(() => {
        if (errorCode === 4) {
            dispatch(loginRequest({
                id,
                name,
                phone
            }, 1));
        }
    }, [errorCode]);

    const codeClickRef = (e) => {
        if (e.target.value) {
            if (e.keyCode === 13 && !codeInput.error) {
                codeRef.current.blur();
                btnRef.current.click();
            }
        } else {
            setCodeInput({...codeInput, helper: "The field must not be empty", error: true});
        }
    };

    const handleCodeChange = (e) => {
        const value = e.target.value;

        value.length ?
            setCodeInput({...codeInput, text: value, helper: "", error: false}) :
            setCodeInput({...codeInput, text: value, helper: "The field must not be empty", error: true});
    };

    const loginCodeClick = () => {
        dispatch({type: PAGE_NEXT_STEP, payload: 1});
    };

    return (
        <div className="game__element">
            <LeftSide>
                {
                    isLoading ? <Preloader/> : <>
                        <Button handleClick={() => dispatch(moveBack())} className={"btn btn-close"}>
                            <icons.Close/>
                            <span>{t("Close")}</span>
                        </Button>
                        <Audio audio={{wav: audios[lang].audioCodeWav, mp3: audios[lang].audioCodeMp3}}
                               subtitle={subtitles[lang].code} autoplay={true}/>
                        <Title>{t("Enter the code")}</Title>
                        <FormControl info={codeInput} isCode={true} label={t("Code from SMS")}>
                            <input className="form-control__input" value={codeInput.text} onChange={handleCodeChange}
                                   type="text" onKeyUp={codeClickRef} ref={codeRef}/>
                        </FormControl>
                        <div className="left-side__error">{
                            typeof errorMsg === "string" ? t(errorMsg) : t("Something is wrong with sms-service!")
                        }</div>
                        <Button handleClick={loginCodeClick} innerRef={btnRef} className="btn btn-primary"
                                disabled={disabledBtn}>{t("Next")}</Button>
                    </>
                }
            </LeftSide>
            <RightSide className="right-side--blur"/>
        </div>
    );
};

export default memo(GameCode);
