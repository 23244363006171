import {useMemo} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Title.scss";

const Title = ({ children, className }) => {
   const classes = useMemo(() => (
       classNames(
           'title',
           {
              [`${className}`]: !!className
           })
   ), []);

   return (
      <h2 className={classes}>{children}</h2>
   );
};

Title.propTypes = {
   className: PropTypes.string,
   children: PropTypes.node.isRequired,
};

export default Title;
