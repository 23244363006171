import "./Info.scss";
import icons from "@assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {gameTimeSelector} from "@/selectors";
import {useEffect} from "react";
import {addTime} from "@/actionCreators";

const InfoTime = () => {
    const dispatch = useDispatch();
    const time = useSelector(gameTimeSelector);

    useEffect(() => {
        const timer = setInterval(() => dispatch(addTime(time)), 10);

        return () => clearInterval(timer);
    });

    return (
        <li className="info__item">
            <icons.Clock/>
            <p className="info__text">
                {
                    time.h > 0 ?
                        <>
                            <span>{time.h}&#58;</span>
                            <span>{time.m >= 10 ? time.m : "0" + time.m}&#58;</span>
                        </> :
                        <span>{time.m}&#58;</span>
                }

                <span>{time.s >= 10 ? time.s : "0" + time.s}</span>
            </p>
        </li>
    );
}

export default InfoTime;