import PropTypes from "prop-types";
import "./RightSide.scss";

const RightSide = ({ className, children }) => {
   return <div className={"right-side " + className}>{children}</div>;
};

RightSide.propTypes = {
   className: PropTypes.string.isRequired,
   children: PropTypes.node,
};

export default RightSide;
