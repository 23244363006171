import {Button} from "@/components";
import {Audio} from "@modularComponents/index";
import '@/i18n';
import {useTranslation} from "react-i18next";
import icons from "@assets/icons";
import "./Intro.scss";
import {useDispatch} from "react-redux";
import {changePage} from "@/actionCreators";
import {scrollTo} from "@/utils";
import {Parallax, ParallaxLayer} from '@react-spring/parallax';
import {
    abpImage, abpImageWebp,
    amazonImage, amazonImageWebp,
    buildingOneImage, buildingOneImageWebp,
    buildingTwoImage, buildingTwoImageWebp,
    carsGif,
    cityParallaxImage, cityParallaxImageWebp,
    costelImage, costelImageWebp,
    darkGardenParallaxImage, darkGardenParallaxImageWebp,
    darkRoofParallaxImage, darkRoofParallaxImageWebp,
    darkWallParallaxImage, darkWallParallaxImageWebp,
    facebookImage, facebookImageWebp,
    gardenImage, gardenImageWebp,
    googleImage, googleImageWebp,
    highwayImage, highwayImageWebp,
    roofImage, roofImageWebp,
    sidesBuildingImage, sidesBuildingImageWebp,
    tiktokImage, tiktokImageWebp,
    topTreeImage, topTreeImageWebp,
    wallImage, wallImageWebp
} from "@assets/images";
import {memo, useContext, useEffect, useState} from "react";
import subtitles from "@/subtitles";
import {audios} from "@assets/audios";
import {AppContext} from "@/App";

const Intro = ({audioRefCB, audioRef}) => {
    const {lang} = useContext(AppContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [playingState, SetPlayingState] = useState(null);
    const [muteState, SetMuteState] = useState(null);
    const {playedStatus} = useContext(AppContext);

    const playingStateCB = (isPlaying, setIsPlaying) => SetPlayingState({isPlaying, setIsPlaying});
    const muteStateCB = (isMuted, setIsMuted) => SetMuteState({isMuted, setIsMuted});

    const onScroll = (prx, ref) => {
        const pointEvent = prx.offsetHeight + Math.ceil(prx.scrollTop);
        const darkElemArr = document.querySelectorAll(".dark");

        darkElemArr.forEach(elem => {
            if (pointEvent >= prx.scrollHeight * 0.85) {
                elem.classList.add("fade");
            } else {
                elem.classList.remove("fade");
            }
        });

        if (pointEvent === prx.scrollHeight) {
            const playPromise = ref?.current?.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    playingState.setIsPlaying(true);
                    muteState.setIsMuted(false);
                }).catch(_ => {
                    playingState.setIsPlaying(false);
                    muteState.setIsMuted(true);
                });
            }
        }
    };

    useEffect(() => {
        const parallax = document.querySelector("#parallax");
        parallax.addEventListener("scroll", () => onScroll(parallax, audioRef));
    }, [audioRef]);

    const handleClick = () => {
        const parallax = document.querySelector("#parallax");
        scrollTo(parallax, 3500);
    };

    return (
        <section className="intro">
            <Parallax id="parallax" className="intro__parallax" config={{duration: 0}} pages={1.7}
                      style={{top: '0', left: '0'}}>
                <ParallaxLayer
                    offset={0}
                    speed={-0.15}
                >
                    <picture>
                        <source srcSet={cityParallaxImageWebp} type="image/webp"/>
                        <source srcSet={cityParallaxImage} type="image/jpeg"/>
                        <img className="intro__city" src={cityParallaxImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.1}
                >
                    <picture>
                        <source srcSet={amazonImageWebp} type="image/webp"/>
                        <source srcSet={amazonImage} type="image/png"/>
                        <img className="intro__amazon" src={amazonImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.15}
                >
                    <picture>
                        <source srcSet={sidesBuildingImageWebp} type="image/webp"/>
                        <source srcSet={sidesBuildingImage} type="image/png"/>
                        <img className="intro__sides-building" src={sidesBuildingImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.2}
                >
                    <picture>
                        <source srcSet={tiktokImageWebp} type="image/webp"/>
                        <source srcSet={tiktokImage} type="image/png"/>
                        <img className="intro__tiktok" src={tiktokImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.25}
                >
                    <picture>
                        <source srcSet={costelImageWebp} type="image/webp"/>
                        <source srcSet={costelImage} type="image/png"/>
                        <img className="intro__costel" src={costelImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.1}
                >
                    <img className="intro__cars" src={carsGif} alt=""/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.3}
                >
                    <picture>
                        <source srcSet={abpImageWebp} type="image/webp"/>
                        <source srcSet={abpImage} type="image/png"/>
                        <img className="intro__abp" src={abpImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.35}
                >
                    <picture>
                        <source srcSet={facebookImageWebp} type="image/webp"/>
                        <source srcSet={facebookImage} type="image/png"/>
                        <img className="intro__facebook" src={facebookImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.4}
                >
                    <picture>
                        <source srcSet={googleImageWebp} type="image/webp"/>
                        <source srcSet={googleImage} type="image/png"/>
                        <img className="intro__google" src={googleImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.45}
                >
                    <picture>
                        <source srcSet={highwayImageWebp} type="image/webp"/>
                        <source srcSet={highwayImage} type="image/png"/>
                        <img className="intro__highway" src={highwayImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={0.3}
                    className="intro__parallax-info"
                >
                    <div onClick={handleClick} className="intro__info">
                        <h2 className="intro__title">
                            <span>{t("Log in")}</span>
                            <span>{t("in ABP")}</span>
                        </h2>
                        <Button className="btn btn-scroll">
                            <icons.Mouse/>
                            <span>{t("Scroll / Swipe to Start")}</span>
                        </Button>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.5}
                >
                    <picture>
                        <source srcSet={buildingOneImageWebp} type="image/webp"/>
                        <source srcSet={buildingOneImage} type="image/png"/>
                        <img className="intro__building1" src={buildingOneImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.55}
                >
                    <picture>
                        <source srcSet={buildingTwoImageWebp} type="image/webp"/>
                        <source srcSet={buildingTwoImage} type="image/png"/>
                        <img className="intro__building2" src={buildingTwoImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={0}
                    className="intro__bottom"
                >
                    <div className="intro__game">
                        <div className="intro__start">
                            {
                                !playedStatus && <>
                                    <Audio
                                        audio={{wav: audios[lang].audioIntroWav, mp3: audios[lang].audioIntroMp3}}
                                        subtitle={subtitles[lang].intro}
                                        autoplay={false}
                                        handleGetRef={audioRefCB}
                                        handleGetPlayingState={playingStateCB}
                                        handleGetMuteState={muteStateCB}
                                    />

                                    <Button
                                        handleClick={() => dispatch(changePage())}
                                        className="intro__btn btn btn-primary"
                                    >{t("Start game")}</Button>
                                </>
                            }
                        </div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.15}
                >
                    <picture>
                        <source srcSet={roofImageWebp} type="image/webp"/>
                        <source srcSet={roofImage} type="image/png"/>
                        <img className="intro__roof" src={roofImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.15}
                >
                    <picture>
                        <source srcSet={darkRoofParallaxImageWebp} type="image/webp"/>
                        <source srcSet={darkRoofParallaxImage} type="image/png"/>
                        <img className="intro__roof dark" src={darkRoofParallaxImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.1}
                >
                    <picture>
                        <source srcSet={gardenImageWebp} type="image/webp"/>
                        <source srcSet={gardenImage} type="image/png"/>
                        <img className="intro__garden" src={gardenImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-0.1}
                >
                    <picture>
                        <source srcSet={darkGardenParallaxImageWebp} type="image/webp"/>
                        <source srcSet={darkGardenParallaxImage} type="image/png"/>
                        <img className="intro__garden dark" src={darkGardenParallaxImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={0.2}
                >
                    <picture>
                        <source srcSet={topTreeImageWebp} type="image/webp"/>
                        <source srcSet={topTreeImage} type="image/png"/>
                        <img className="intro__tree-top" src={topTreeImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0.8}
                    speed={0.15}
                >
                    <picture>
                        <source srcSet={wallImageWebp} type="image/webp"/>
                        <source srcSet={wallImage} type="image/png"/>
                        <img className="intro__wall" src={wallImage} alt=""/>
                    </picture>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0.8}
                    speed={0.15}
                >
                    <picture>
                        <source srcSet={darkWallParallaxImageWebp} type="image/webp"/>
                        <source srcSet={darkWallParallaxImage} type="image/png"/>
                        <img className="intro__wall dark" src={darkWallParallaxImage} alt=""/>
                    </picture>
                </ParallaxLayer>
            </Parallax>
        </section>
    );
};

export default memo(Intro);
