import {ru} from "@assets/audios/ru";
import {es} from "@assets/audios/es";
import {ua} from "@assets/audios/ua";
import backgroundMp3 from "@assets/audios/background.mp3";
import triangleMp3 from "@assets/audios/triangle.mp3";
import rectangleMp3 from "@assets/audios/rectangle.mp3";
import backgroundWav from "@assets/audios/background.wav";
import triangleWav from "@assets/audios/triangle.wav";
import rectangleWav from "@assets/audios/rectangle.wav";

export const audios = {
    ru,
    es,
    ua,
}

export {
    backgroundMp3,
    triangleMp3,
    rectangleMp3,
    backgroundWav,
    triangleWav,
    rectangleWav,
};
