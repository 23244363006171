import {Button} from "@/components";
import icons from "@assets/icons";
import PropTypes from "prop-types";

const AudioControl = ({play, isPlaying, duration, currentTime, children}) => {
    const secondsToHms = (seconds) => {
        if (!seconds) return "0:00";

        let duration = seconds;
        let hours = duration / 3600;
        duration = duration % 3600;

        let min = parseInt(duration / 60);
        duration = duration % 60;

        let sec = parseInt(duration);

        if (sec < 10) {
            sec = "0" + sec;
        }

        if (parseInt(hours, 10) > 0) {
            return `${min}:${sec}`;
        } else if (min == 0) {
            return `0:${sec}`;
        } else {
            return `${min}:${sec}`;
        }
    }

    return (
        <div className='audio__control'>
            <Button handleClick={play} className="btn btn-audio-control audio__btn">
                {isPlaying ? <icons.Pause/> : <icons.Play/>}
            </Button>
            {children}
            <div className='audio__timer'>{secondsToHms(currentTime)}</div>
            <div className='audio__timer'>/</div>
            <div className='audio__timer'>{secondsToHms(duration)}</div>
        </div>
    )
};

AudioControl.propTypes = {
    play: PropTypes.func.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    currentTime: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

export default AudioControl;