import "./Game.scss";
import {animated} from "react-spring";
import PropTypes from "prop-types";
import {memo, useMemo} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {pageCounterSelector} from "@/selectors";

const Game = ({style, children}) => {
    const pageCounter = useSelector(pageCounterSelector);

    const classes = useMemo(() => (
        classNames(
            "game",
            {
                ["game--pos"]: pageCounter >= 1
            })
    ), []);

    return (
        <animated.div className={classes} style={style}>
            {children}
        </animated.div>
    );
};

Game.propTypes = {
    children: PropTypes.node.isRequired,
    styles: PropTypes.object,
};

export default memo(Game);